import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  DELETE_WEBFORM,
  LIST_WEBFORM,
  UPDATE_WEBFORM,
  LIST_WORKFLOW,
  CREATE_WORKFLOW,
  DELETE_WORKFLOW,
  LIST_REQUEST,
  LIST_SUBTASK,
  CREATE_REQUEST,
  DELETE_REQUEST,
  CREATE_SUBTASK,
  DELETE_SUBTASK,
  UPDATE_SUBTASK,
  UPDATE_WORKFLOW,
  CLOSE_REQUEST,
  REJECT_REQUEST,
  GET_LIST_WORKFLOW_BYID,
  DELETE_SUBTASK_IN_WORKFLOW,
} from "../constants/Dsar";
import {
  listWebForm,
  listRequest,
  listWorkFlow,
  listSubtask,
  setListWebForm,
  setListWorkFlow,
  setListRequest,
  setListSubtask,
  changeLoading,
  changeLoader,
  setActionLoading,
  setListWorkFlowDataByID,
  setErrorCaseMessage,
  updateWorkflowLoading,
  callBackEvent
} from "../actions/Dsar";
import DSARService from "services/DSARService";
import OpenNotification from "../../views/components/OpenNotification";

export function* listWebForms() {
  yield takeEvery(LIST_WEBFORM, function* () {
    yield put(changeLoader(true));
    try {
      const resp = yield call(DSARService.listWebForm);
      if (resp.statusCode === 200) {
        yield put(setListWebForm(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteWebForm() {
  yield takeEvery(DELETE_WEBFORM, function* ({ payload }) {
    try {
      // return yield call(DSARService.deleteWebForm, payload);
      if (payload.statusCode === 200) {
        yield put(listWebForm());
        yield put(changeLoading(false));
        OpenNotification("success", "deletewebformsuccess");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateWebForms() {
  yield takeEvery(UPDATE_WEBFORM, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.updateWebForm, payload);
      if (resp.statusCode === 200) {
        yield put(changeLoading(false));
        OpenNotification("success", "updatewebformsuccess");
      } else {
        yield put(changeLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* listWorkFlows() {
  yield takeEvery(LIST_WORKFLOW, function* () {
    yield put(changeLoader(true));
    try {
      const resp = yield call(DSARService.listWorkflow);
      if (resp.statusCode === 200) {
        yield put(setListWorkFlow(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* listWorkFlowsByID() {
  yield takeEvery(GET_LIST_WORKFLOW_BYID, function* ({ payload }) {
    yield put(setListWorkFlowDataByID(null));
    yield put(changeLoader(true));
    try {
      const resp = yield call(DSARService.listWorkflowByID, payload);
      if (resp.statusCode === 200) {
        yield put(setListWorkFlowDataByID(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* createWorkFlows() {
  yield takeEvery(CREATE_WORKFLOW, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.createWorkflow, payload);
      if (resp.statusCode === 200) {
        yield put(listWorkFlow());
        yield put(setActionLoading(false));
        OpenNotification("success", "");
      }
    } catch (err) {
      console.log(err);
      yield put(setActionLoading(false));
    }
  });
}

export function* deleteWorkFlow() {
  yield takeEvery(DELETE_WORKFLOW, function* ({ payload }) {
    yield put(setErrorCaseMessage({ enable: false, text: "" }));
    try {
      const resp = yield call(DSARService.deleteWorkFlow, payload);
      if (resp.statusCode === 200) {
        yield put(listWorkFlow());
        OpenNotification("success", "");
        yield put(setActionLoading(false));
      }

      if (resp.statusCode === 405) {
        yield put(setErrorCaseMessage({ enable: true, text: resp.error }));
        yield put(setActionLoading(true));
      }
    } catch (err) {
      console.log(err);

      yield put(setActionLoading(false));
      yield put(
        setErrorCaseMessage({
          enable: true,
          text: "The connection to the server was interrupted.",
        })
      );
    }
  });
}

export function* listRequests() {
  yield takeEvery(LIST_REQUEST, function* () {
    try {
      const resp = yield call(DSARService.listRequest);
      if (resp.statusCode === 200) {
        yield put(setListRequest(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* createRequest() {
  yield takeEvery(CREATE_REQUEST, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.createRequest, payload);
      if (resp.statusCode === 200) {
        yield put(listRequest());
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteRequest() {
  yield takeEvery(DELETE_REQUEST, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.deleteRequest, payload);
      if (resp.statusCode === 200) {
        yield put(listRequest());
        OpenNotification("success", "");
      }
      if (resp.statusCode === 404) {
        OpenNotification("error", "DoNotHavePermission");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* closeRequest() {
  yield takeEvery(CLOSE_REQUEST, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.closeRequest, payload);
      if (resp.statusCode === 200) {
        yield put(listRequest());
        OpenNotification("success", "");
      }
      if (resp.statusCode === 404 || resp.statusCode === 500) {
        OpenNotification("error", "DoNotHavePermission");
      }
      console.log(resp);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* rejectRequest() {
  yield takeEvery(REJECT_REQUEST, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.rejectRequest, payload);
      if (resp.statusCode === 200) {
        yield put(listRequest());
        OpenNotification("success", "");
      } else {
        OpenNotification("error", "DoNotHavePermission");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* listSubtasks() {
  yield takeEvery(LIST_SUBTASK, function* () {
    try {
      const resp = yield call(DSARService.listSubtask);
      if (resp.statusCode === 200) {
        yield put(setListSubtask(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* createSubtask() {
  yield takeEvery(CREATE_SUBTASK, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.createSubtask, payload);
      if (resp.statusCode === 200) {
        yield put(listSubtask());
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteSubtask() {
  yield takeEvery(DELETE_SUBTASK, function* ({ payload }) {
    try {
      // const resp = yield call(DSARService.deleteSubtask, payload);
      if (payload.statusCode === 200) {
        yield put(listSubtask());
        OpenNotification("success", "deletesubtasksuccess");
      }
      if (payload.statusCode === 404) {
        OpenNotification("error", "DoNotHavePermission");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteSubtaskInWorkflow() {
  yield takeEvery(DELETE_SUBTASK_IN_WORKFLOW, function* ({ payload }) {
    yield put(callBackEvent({ type: "pending" }));
    try {
      const resp = yield call(DSARService.deleteSubtaskInWorkflow, payload);
      if (resp.statusCode === 200) {
        yield put(listSubtask());
        yield put(callBackEvent({ type: "success" }));
        OpenNotification("success", "deletesubtasksuccess");
      } else {
        yield put(callBackEvent({ type: "error", msg: "Something went wrong. Refresh the page and try again." }));
      }
    } catch (err) {
      yield put(callBackEvent({ type: "error", msg: "The connection to the server was interrupted." }));
    }
  });
}

export function* updateSubtask() {
  yield takeEvery(UPDATE_SUBTASK, function* ({ payload }) {
    try {
      const resp = yield call(DSARService.updateSubtask, payload);
      if (resp.statusCode === 200) {
        yield put(listSubtask());
        OpenNotification("success", "");
      } else {
        OpenNotification("error", "");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateWorkflow() {
  yield takeEvery(UPDATE_WORKFLOW, function* ({ payload }) {
    yield put(updateWorkflowLoading(true));
    try {
      const resp = yield call(DSARService.updateWorkflow, payload);
      if (resp.statusCode === 200) {
        OpenNotification("success", "");
        yield put(updateWorkflowLoading(false));
      } else {
        OpenNotification("error", "");
        yield put(updateWorkflowLoading(false));
      }
    } catch (err) {
      console.log(err);
      OpenNotification("error", "");
      yield put(updateWorkflowLoading(false));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listWebForms),
    fork(deleteWebForm),
    fork(updateWebForms),
    fork(listWorkFlows),
    fork(createWorkFlows),
    fork(deleteWorkFlow),
    fork(listRequests),
    fork(listSubtasks),
    fork(createRequest),
    fork(deleteRequest),
    fork(createSubtask),
    fork(deleteSubtask),
    fork(updateSubtask),
    fork(updateWorkflow),
    fork(closeRequest),
    fork(rejectRequest),
    fork(listWorkFlowsByID),
    fork(deleteSubtaskInWorkflow),
  ]);
}
