import fetch from "auth/FetchInterceptor";
import fetchBeta from "auth/FetchBetaAPI";

const PdpakitService = {};

PdpakitService.addDomain = async (data) =>
  await fetch({ method: "post", url: "/domain", data: { data } })
    .then((resp) => resp)
    .catch((err) => err);

PdpakitService.deleteDomain = async (key) =>
  await fetch({ method: "delete", url: `/domain/${key}` })
    .then((resp) => resp)
    .catch((err) => err);

PdpakitService.listDomain = async () =>
  await fetch({ method: "get", url: "/domain" })
    .then((resp) => resp)
    .catch((err) => err);

PdpakitService.getDomainInfo = async (key) => {
  return await fetch({ method: "get", url: `/domain/${key}` })
    .then((resp) => resp)
    .catch((err) => err);
};

PdpakitService.scanCookies = async (key) => {
  return await fetch({ method: "post", url: `/cookie/${key}` })
    .then((resp) => resp)
    .catch((err) => err);
};

PdpakitService.updateCookiesCategoryAPI = async (data) => {
  return await fetch({
    method: "PUT",
    url: `/cookie/${data.data.domain_key}`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.updateDomain = async (data) => {
  return await fetch({
    method: "PUT",
    url: `/domain/${data.data.key}`,
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.getQueuedAcceptConsent = async (key) => {
  return await fetch({ method: "POST", url: `/dashboard/accept/${key}` })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.getAcceptConsent = async (key) => {
  return await fetch({ method: "GET", url: `/dashboard/accept/${key}` })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.getPolicyMeta = async () => {
  return await fetch({ method: "GET", url: `/policy` })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.createPolicy = async (data) => {
  let path = data.ObjectType ? data.ObjectType : "newpolicy";
  return await fetch({ method: "POST", url: `/policy/${path}`, data: { data } })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.updatePolicy = async (data) => {
  return await fetchBeta({
    method: "POST",
    url: `/policy/${data.ObjectType}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.previewPolicy = async (data) => {
  return await fetch({
    method: "GET",
    url: `/policy/privacy/html?key=${data.ObjectType}&user=${
      data.ObjectID
    }&preview=1&lang=${data.lang}${
      data.verkey ? `&verkey=${data.verkey}` : ""
    }`,
  })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.previewSamplePolicy = async (data) => {
  return await fetchBeta({
    method: "POST",
    url: `/policy/privacy/document?key=&user=&preview=1&lang=${data.lang}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.listPolicy = async () => {
  return await fetchBeta({ method: "GET", url: "/policy/listallname" })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.getPolicy = async (data) => {
  let url = `/policy/privacy?key=${data.ObjectType}&user=${data.ObjectID}&lang=${data.lang}`;
  if (data.verkey) {
    url = url.concat(`&verkey=${data.verkey}`);
  }
  return await fetch({ method: "GET", url })
    .then((resp) => resp)
    .catch((err) => err);
};

PdpakitService.deletePolicy = async (key) => {
  return await fetchBeta({ method: "DELETE", url: `/policy/${key}` })
    .then((resp) => resp)
    .catch((resp) => resp);
};

PdpakitService.deletePolicyLang = async (key, data) => {
  return await fetchBeta({
    method: "POST",
    url: `/policy/privacy/document/removelang/${key}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

PdpakitService.getPolicyVersion = async (key) => {
  return await fetchBeta({ method: "GET", url: `/policy/versions/${key}` })
    .then((resp) => resp)
    .catch((err) => err);
};

PdpakitService.publicPolicyVersionKey = async (key) => {
  return await fetchBeta({ method: "POST", url: `/policy/publish/${key}` })
    .then((resp) => resp)
    .catch((err) => err);
};

export default PdpakitService;
