import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { WEBFORM_PREFIX_PATH } from "configs/AppConfig";
import { connect } from "react-redux";
import { authenticated } from "../../redux/actions/Auth";

export const WebformViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`/form/preview`}
          component={lazy(() => import(`views/webform-views/form-view`))}
        />

        <Route
          path={`/form/:id/:objId/:formName`}
          component={lazy(() => import(`views/webform-views/dsar`))}
        />

        <Redirect from={WEBFORM_PREFIX_PATH} to={`/form/`} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = ({ auth }) => {
  const { refresh_token, token } = auth;
  return { refresh_token, token };
};

export default React.memo(
  connect(mapStateToProps, { authenticated })(WebformViews)
);
