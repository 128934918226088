import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SET_USER,
  SET_CHANGE_PROFILE,
  HIDE_LOADING,
  SIGNOUT,
  AUTH_CENTER_LAYOUT,
  INFO_PERSON_PROFILE,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  Username: "",
  isCenterLayout: false,
};

const auth = (state = initState, action) => {
  const { type, payload, data, message } = action;
  switch (type) {
    case AUTHENTICATED: {
      return {
        ...state,
        ...data,
        Username: data.Username ? data.Username : state.Username,
        loading: false,
        redirect: "/",
        AccessToken: data.AccessToken ? data.AccessToken : state.access_token,
        peoplePolice: data.peoplePolice,
        saveTime: Math.floor(Date.now() / 1000),
      };
    }
    case AUTH_CENTER_LAYOUT: {
      return {
        ...state,
        isCenterLayout: payload,
      };
    }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message,
        showMessage: true,
        // loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
      };
    }
    case SIGNOUT: {
      // return {
      //   ...state,
      //   token: null,
      //   redirect: "/",
      //   loading: false,
      // };
      return {
        ...state,
        Username: "",
        loading: false,
        redirect: "/",
        email: "",
        AccessToken: "",
        peoplePolice: "",
        saveTime: "",
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_USER: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_CHANGE_PROFILE: {
      let { UserAttributes } = state;
      const username = payload.filter((e) => e.Name === "custom:username");
      const img = payload.filter((e) => e.Name === "custom:profile_image");
      UserAttributes = UserAttributes.filter(
        (data) =>
          data.Name !== "custom:username" &&
          data.Name !== "custom:profile_image"
      );
      UserAttributes.push(
        {
          Name: "custom:username",
          Value: username.length > 0 ? username[0]["Value"] : "",
        },
        {
          Name: "custom:profile_image",
          Value: img.length > 0 ? img[0]["Value"] : "",
        }
      );
      return {
        ...state,
        loading: false,
        UserAttributes,
      };
    }
    case INFO_PERSON_PROFILE: {
      return {
        ...state,
        infoProfile: payload,
      };
    }
    default:
      return state;
  }
};

export default auth;
