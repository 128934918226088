import fetch from "axios/FetchDSAR";

const DSARService = {};

DSARService.createWebForm = async (data) =>
  await fetch({ method: "post", url: "/webform", data: { data } })
    .then((resp) => resp)
    .catch((err) => err);

DSARService.listWebForm = async () =>
  await fetch({ method: "get", url: "/webform?search" })
    .then((resp) => resp)
    .catch((err) => err);

DSARService.deleteWebForm = async (data) =>
  await fetch({
    method: "delete",
    url: `/webform/${data.ObjectUUID}?user_key=${data.created_by}`,
  })
    .then((resp) => resp)
    .catch((err) => err);

DSARService.updateWebForm = async (data) => {
  const { ObjectUUID, created_by } = data;
  return await fetch({
    method: "put",
    url: `/webform/${ObjectUUID}?user_key=${created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createWorkflow = async (data) => {
  return await fetch({ method: "post", url: "/workflow", data: { data } })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.listWorkflow = async () => {
  return await fetch({ method: "get", url: "/workflow/default" })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.listWorkflowByPaging = async (data) => {
  return await fetch({
    method: "get",
    url: `/workflow?next_page_token=${data}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.listWorkflowByID = async (data) => {
  return await fetch({
    method: "get",
    url: `/workflow/${data.ObjectUUID}?user_key=${data.userKey}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.deleteWorkFlow = async (data) => {
  return await fetch({
    method: "delete",
    url: `/workflow/${data.ObjectUUID}?user_key=${data.userKey}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createRequestInWebForm = async (data) => {
  const { ObjectUUID } = data;
  return await fetch({
    method: "post",
    url: `/webform/${ObjectUUID}/request`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createRequest = async (data) => {
  return await fetch({ method: "post", url: "/request", data: { data } })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.updateRequest = async (data) => {
  return await fetch({
    method: "put",
    url: `/request/${data.ObjectUUID}?user_key=${data.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.listRequest = async () => {
  return await fetch({ method: "get", url: "/request?search&filter=all" })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.getRequestById = async (request_id,user_key) => {
  return await fetch({ method : "get", url : `/request/${request_id}?user_key=${user_key}`})
    .then((resp)=> resp)
    .catch((err)=>err)
}

DSARService.getRequestLog = async (request_id, user_key) => {
  return await fetch({ method : "get", url : `/request/${request_id}/logs?user_key=${user_key}`})
    .then((resp)=>resp)
    .catch((err)=>err)
}

DSARService.getSubtaskLog = async (subtask_id, user_key) => {
  return await fetch({ method : "get", url : `/subtask/${subtask_id}/logs?user_key=${user_key}`})
    .then((resp)=>resp)
    .catch((err)=>err)
}

DSARService.listSubtaskInRequest = async (request_id) => {
  return await fetch({ method: "get", url: `/request/${request_id}/subtask` })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createSubtask = async (data) => {
  return await fetch({ method: "post", url: "/subtask", data: { data } })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.updateSubtaskInWorkflow = async (data) => {
  const newData = {
    assignee: data.assignee,
    comment_required: data.comment_required,
    comments: data.comments,
    deadline_at: data.deadline_at,
    detail: data.detail,
    object_class: data.object_class,
    reminder_at: data.reminder_at,
    resolution_required: data.resolution_required,
    stage_order: data.stage_order,
    stage_title: data.stage_title,
    state: data.state,
    status: data.status,
    subtask_required: data.subtask_required
  }

  return await fetch({
    method: "put",
    url: `/subtask/${data.ObjectUUID}?workflow_id=${data.workflow_id}&user_key=${data.created_by}`,
    data: { data: newData },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.updateSubtask = async (data) => {
  data.ObjectID = undefined;
  data.ObjectSortKey = undefined;
  data.request_data = undefined;
  data["Subtask[ObjID]"] = undefined;
  return await fetch({
    method: "put",
    url: `/subtask/${data.ObjectUUID}/?request_id=${data.request_id}&user_key=${data.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.deleteSubtask = async (data) => {
  return await fetch({
    method: "delete",
    url: `/subtask/${data.ObjectUUID}?request_id=${data.request_id}&user_key=${data.created_by}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.deleteSubtaskInWorkflow = async (data) => {
  return await fetch({
    method: "delete",
    url: `/subtask/${data.ObjectUUID}?workflow_id=${data.workflow_id}&user_key=${data.created_by}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.assignApproverInRequest = async (data) => {
  let { request_id, created_by } = data;
  data["request_id"] = undefined;
  data["created_by"] = undefined;
  return await fetch({
    method: "post",
    url: `/request/${request_id}/assign?user_key=${created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.listSubtask = async () => {
  return await fetch({ method: "get", url: "/subtask?search" })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.updateWorkflow = async (data) => {
  return await fetch({
    method: "put",
    url: `/workflow/${data.ObjectUUID}?user_key=${data.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.listActivityInRequest = async (data) => {
  return await fetch({
    method: "get",
    url: `/request/${data.ObjectUUID}/activities?user_key=${data.created_by}&search&filter=all`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createActivityInRequest = async (request_data, activities) => {
  return await fetch({
    method: "post",
    url: `/request/${request_data.ObjectUUID}/activities?user_key=${request_data.created_by}`,
    data: { data: { activities } },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.getSubtaskIdInRequest = async (data) => {
  return await fetch({
    method: "get",
    url: `/request/${data.ObjectUUID}/subtask?user_key=${data.created_by}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.getSubtaskInWorkflow = async (data) => {
  return await fetch({
    method: "get",
    url: `/workflow/${data.workflow_id}/subtask`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createSubtaskInWorkflow = async (data) => {
  return await fetch({
    method: "post",
    url: `/workflow/${data.ObjectUUID}/subtask?user_key=${data.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createSubtaskInRequest = async (data) => {
  return await fetch({
    method: "post",
    url: `/request/${data.request_id}/subtask?user_key=${data.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.rejectRequest = async (item) => {
  let data = { ...item };
  data["request_id"] = undefined;
  data["created_by"] = undefined;
  return await fetch({
    method: "post",
    url: `/request/${item.request_id}/reject?user_key=${item.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.closeRequest = async (data) => {
  return await fetch({
    method: "post",
    url: `/request/${data.request_id}/close?user_key=${data.created_by}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.deleteRequest = async (data) => {
  return await fetch({
    method: "delete",
    url: `/request/${data.ObjectUUID}?user_key=${data.created_by}`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.listVerificationInRequest = async (request_id) => {
  return await fetch({
    method: "get",
    url: `/request/${request_id}/verification`,
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.createVerificationInRequest = async (data) => {
  return await fetch({
    method: "post",
    url: `/request/${data.request_id}/verification?user_key=${data.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

DSARService.updateVerificationInRequest = async (data) => {
  return await fetch({
    method: "post",
    url: `/request/${data.request_id}/verification/${data.verification_id}?user_key=${data.created_by}`,
    data: { data },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

export default DSARService;
