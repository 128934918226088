import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Pdpakit from "./Pdpakit";
import Dsar from "./Dsar";
import Orgs from "./Orgs";
import Portal from "./Portal";
import Formtd from "./Formtd";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  pdpakit: Pdpakit,
  dsar: Dsar,
  orgs: Orgs,
  portal: Portal,
  formtd: Formtd
});

export default reducers;
