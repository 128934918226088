import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  Menu,
  Form,
  Select,
  Modal,
  Input,
  Divider,
  Row,
  Col,
  Dropdown,
} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import Logo from "./Logo";
import NavProfile from "./NavProfile";
import NavLanguage from "./NavLanguage";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import { setSelectKey } from "../../redux/actions/Pdpakit";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { Link, useLocation } from "react-router-dom";
import Flex from "components/shared-components/Flex";
import IntlMessage from "components/util-components/IntlMessage";
import { CaretDownOutlined, AppstoreOutlined } from "@ant-design/icons";
import { PDPAKIT_MODULE } from "constants/AppModules";
import IconCustom from "@ant-design/icons";
import {
  Newcookies,
  Newdatasubject,
  Newpolicy,
} from "assets/svg/iconantdcustom";
import { CircleBackgroundIcon } from "components/shared-components/CircleBackgroundIcon";
import styled from "styled-components";
import { authenticated, signOut } from "redux/actions/Auth";
import { showContactChange } from "redux/actions/Theme";

import FeedbackService from "services/FeedbackService";
import OpenNotification from "views/components/OpenNotification";
import { useLanguageLabel } from "hooks/useLanguageCustom";
import JwtAuthService from "../../services/JwtAuthService";
import { useIdleTimer } from "react-idle-timer";
import Text from "antd/es/typography/Text";

const { Option } = Select;
const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    domain,
    select_key,
    setSelectKey,
    showContact,
    showContactChange,
    hideSidebar,
    authenticated,
    refresh_token,
    signOut,
    email,
    isOnlyLogo = false
  } = props;

  const [loading, setloading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [PATH, SET_PATH] = useState(window.location.href);
  const [USER_IDLE, SET_USER_IDLE] = useState(false);
  const [LOADING, SET_LOADING] = useState(false);
  const [TIMING, SET_TIMING] = useState(60);
  const [TEMP_STORE, SET_TEMP_STORE] = useState(null);

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const location = useLocation();

  const { setMessageId } = useLanguageLabel();

  const CONTACT_RULES = {
    username: [
      {
        required: true,
        message: setMessageId("pdpakit.rules.message.required.username"),
      },
    ],
    tel: [
      {
        required: true,
        message: setMessageId("pdpakit.rules.message.required.tel"),
      },
    ],
    email: [
      {
        required: true,
        message: setMessageId("pdpakit.rules.message.required.email"),
      },
    ],
    website: [
      {
        required: true,
        message: setMessageId("pdpakit.rules.message.required.website"),
      },
    ],
    message: [
      {
        required: true,
        message: setMessageId("pdpakit.rules.message.required.message"),
      },
    ],
  };

  const onSubmitSendContact = (item) => {
    setloading(true);
    const { email, username, message, tel } = item;

    let data = {
      name: username,
      phone: tel,
      email: email,
      website: "website",
      message: message,
      url: window.location.href,
    };
    FeedbackService.customer(data).then((resp) => {
      if (resp.statusCode === 200) {
        FeedbackService.stampSendEmail(item.email).then((resp) => {
          if (resp.statusCode === 200) {
            showContactChange(false);
            setTimeout(() => {
              OpenNotification("success", "sendingemailsuccess");
            }, 2000);
            setloading(false);
          } else {
            showContactChange(false);
            setTimeout(() => {
              OpenNotification("success", "sendingemailsuccess");
            }, 2000);
            setloading(false);
          }
        });
      } else {
        setloading(false);
      }
    });
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  //เพิ่ม style ให้กับ drobdown list ที่เป็ฯ responsive
  const DrobdownResponsive = styled(Select)`
    @media (min-width: 481px) {
      width: 200px;
    }
  `;

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  // const TagCustom = styled(Tag)`
  //   padding: 6px 10px;
  //   border-radius: 0.625rem;
  // `;

  const menu = (
    <Menu
      onClick={(event) => (window.location.href = event.key)}
      className="h-25 w-auto"
    >
      {PDPAKIT_MODULE.map((event) => {
        const { name, path, component, subpath } = event;
        const url = PATH.split("/");
        if (url.includes(subpath)) {
          return null;
        }
        return (
          <Menu.Item key={path}>
            <Flex alignItems="center">
              <CircleBackgroundIcon fontsize={20} circlesize={false}>
                <IconCustom component={component} />
              </CircleBackgroundIcon>
              <label className="ml-2">{name}</label>
            </Flex>
            <Link to={path} />
          </Menu.Item>
        );
      })}

      <Menu.Divider />
      <Flex alignItems="center" justifyContent="center">
        <Button
          type="link"
          onClick={() => window.location.assign("/apps/pdpakit")}
          block
        >
          <IntlMessage id="button.navbar.ourproduct" />
        </Button>
      </Flex>
    </Menu>
  );
  const drobdownChangeMark = () => {
    if (PATH.split("/").includes("cookies")) {
      return {
        icon: <IconCustom component={Newcookies} />,
        text: "Cookie Consent Manager",
        openDDL: true,
      };
    }
    if (PATH.split("/").includes("policy")) {
      return {
        icon: <IconCustom component={Newpolicy} />,
        text: "Policy Management",
        openDDL: true,
      };
    }
    if (PATH.split("/").includes("datasubject")) {
      return {
        icon: <IconCustom component={Newdatasubject} />,
        text: "DSAR Automation",
        openDDL: true,
      };
    }
    if (PATH.split("/").includes("universal")) {
      return {
        icon: <IconCustom component={Newdatasubject} />,
        text: "Consent Management",
        openDDL: true,
      };
    } else {
      return {
        openDDL: false,
      };
    }
  };

  const handleOnIdle = () => {
    SET_TEMP_STORE(JSON.parse(localStorage.getItem("store")));
    localStorage.removeItem("store");
    SET_USER_IDLE(true);
  };

  const handleOnActive = () => {
    let store = JSON.parse(localStorage.getItem("store"));
    store["auth"].saveTime = Math.floor(Date.now() / 1000);
    localStorage.setItem("store", JSON.stringify(store));
  };

  useIdleTimer({
    timeout: 1000 * 60 * 180,
    // timeout: 10000,
    onIdle: handleOnIdle,
    onAction: handleOnActive,
    debounce: 1000 * 60,
    // debounce: 1000
  });

  useEffect(() => {
    if (USER_IDLE && TIMING > 0) {
      setTimeout(() => {
        SET_TIMING(() => TIMING - 1);
      }, 1000);
    }
    if (USER_IDLE && TIMING <= 0) {
      setTimeout(() => {
        if (localStorage.getItem("store")) {
          SET_TIMING(60);
          SET_USER_IDLE(false);
        } else {
          window.location.href = "https://www.onefence.co/th/"
        }
      }, 1000);
    }
  });

  useEffect(() => {
    // console.log(location)
    SET_PATH(location.pathname);
  }, [location]);

  useEffect(() => {
    drobdownChangeMark();
  }, [drobdownChangeMark]);

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo readOnly={isOnlyLogo} logoType={navMode} />
        {!isOnlyLogo && <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
            <div>
              {domain.body.length > 0 &&
              !PATH.split("/").includes("website", 3) &&
              PATH.split("/").includes("cookies") ? (
                <DrobdownResponsive
                  showSearch
                  showArrow={true}
                  placeholder="ไม่มีข้อมูล"
                  optionFilterProp="children"
                  defaultValue={select_key}
                  value={select_key}
                  onChange={(key) => {
                    setSelectKey(key);
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {domain.body
                    ? domain.body.map((event) => {
                        const { key, name } = event;
                        return (
                          <Option key={key} value={key}>
                            {name}
                          </Option>
                        );
                      })
                    : null}
                </DrobdownResponsive>
              ) : null}
            </div>
          </div>
          <div className="nav-right align-items-center px-1 px-sm-0 ">
            {/* {domain.is_package === "free" ? (
              <div className=" mx-lg-2 px-lg-2">
                <Button
                  type="primary"
                  ghost
                  onClick={openSendingContact}
                  className="d-none  d-sm-inline-block"
                >
                  <IntlMessage id="button.navbar.upgrade" />
                </Button>
              </div>
            ) : null} */}

            {PATH.includes("pdpakit") &&
              (drobdownChangeMark().openDDL ? (
                <Dropdown
                  overlay={menu}
                  className="d-none d-sm-inline-block   mx-lg-4 px-lg-4 "
                >
                  <Button>
                    <label
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Flex alignItems="center">
                        <CircleBackgroundIcon fontsize={20} circlesize={false}>
                          {drobdownChangeMark().icon}
                        </CircleBackgroundIcon>
                        <label className="ml-2">
                          {drobdownChangeMark().text}
                        </label>
                        &nbsp;&nbsp;
                        <CaretDownOutlined />
                      </Flex>

                      {/*<Flex alignItems="center">*/}
                      {/*  <AppstoreOutlined />*/}
                      {/*  &nbsp;&nbsp;*/}
                      {/*  <IntlMessage id="button.navbar.ourproduct" />*/}
                      {/*  &nbsp;&nbsp;*/}
                      {/*  <CaretDownOutlined />*/}
                      {/*</Flex>*/}
                    </label>
                  </Button>
                </Dropdown>
              ) : (
                <Dropdown
                  overlay={menu}
                  className="d-none d-sm-inline-block mx-lg-4 px-lg-4"
                >
                  <Button>
                    <label
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Flex alignItems="center">
                        <AppstoreOutlined />
                        &nbsp;&nbsp;
                        <IntlMessage id="button.navbar.ourproduct" />
                        &nbsp;&nbsp;
                        <CaretDownOutlined />
                      </Flex>
                    </label>
                  </Button>
                </Dropdown>
              ))}

            {/*<TagCustom className="d-none d-sm-inline-block">*/}
            {/*  <Flex alignItems="center">*/}
            {/*    <CircleBackgroundIcon circlesize={false} fontsize="15">*/}
            {/*      <IconCustom component={Newcookies} />*/}
            {/*    </CircleBackgroundIcon>*/}
            {/*    <label className="ml-2">Cookie Compliance</label>*/}
            {/*  </Flex>*/}
            {/*</TagCustom>*/}

            {/* <NavLanguage configDisplay={!isMobile} /> */}

            {/* <NavPanel direction={direction} /> */}
            <div id="btn-nav-profile" className=" mx-lg-1 px-lg-1">
              {/* <NavProfile /> */}
            </div>
          </div>
        </div>}
      </div>

      <Modal
        title={
          <>
            <label>
              <IntlMessage id="upgrade.modal.title" />
            </label>
            <br />
            <p className="m-0">
              <IntlMessage id="upgrade.modal.title.sub" />
            </p>
          </>
        }
        visible={showContact}
        onCancel={() => showContactChange(false)}
        footer={false}
        centered
      >
        <Form
          form={form}
          onFinish={onSubmitSendContact}
          layout="vertical"
          initialValues={{
            email: email,
          }}
        >
          <Form.Item
            name="username"
            label={<IntlMessage id="upgrade.modal.name" />}
            rules={CONTACT_RULES.username}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="tel"
            label={<IntlMessage id="upgrade.modal.tel" />}
            rules={CONTACT_RULES.tel}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<IntlMessage id="upgrade.modal.email" />}
            name="email"
            rules={CONTACT_RULES.email}
          >
            <Input disabled />
          </Form.Item>
          {/*<Form.Item*/}
          {/*  label={<IntlMessage id="upgrade.modal.website" />}*/}
          {/*  name="website"*/}
          {/*  rules={CONTACT_RULES.website}*/}
          {/*>*/}
          {/*  <Input allowClear />*/}
          {/*</Form.Item>*/}
          <Form.Item
            className="m-0"
            label={<IntlMessage id="upgrade.modal.message" />}
            name="message"
            rules={CONTACT_RULES.message}
          >
            <TextArea allowClear rows={3} />
          </Form.Item>
          <Divider className="my-3" />
          <Form.Item className="m-0">
            <Row justify="end" gutter={[8, 8]}>
              <Col span="5">
                <Button
                  onClick={() => showContactChange(false)}
                  className="w-100"
                >
                  <IntlMessage id="button.cancel" />
                </Button>
              </Col>
              <Col span="5">
                <Button
                  disabled={loading}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="w-100"
                >
                  {" "}
                  <IntlMessage id="button.submit" />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={<IntlMessage id="general.modal.sessionexpire.title" />}
        centered
        visible={USER_IDLE}
        closable={false}
        // onCancel={()=>SET_USER_IDLE(false)}
        footer={[
          <Button
            onClick={() => {
              signOut();
            }}
          >
            <IntlMessage id="general.modal.sessionexpire.btnsignout" />
          </Button>,
          <Button
            loading={LOADING}
            type="primary"
            onClick={() => {
              SET_LOADING(true);
              SET_TIMING(60);
              JwtAuthService.refreshToken(refresh_token)
                .then((resp) => {
                  localStorage.setItem("store", JSON.stringify(TEMP_STORE));
                  const { AuthenticationResult } = resp.body.data;
                  authenticated(AuthenticationResult);
                  SET_LOADING(false);
                  SET_USER_IDLE(false);
                })
                .catch(() => {
                  SET_LOADING(false);
                  SET_USER_IDLE(false);
                });
            }}
          >
            {" "}
            <IntlMessage id="general.modal.sessionexpire.btnsignin" />
          </Button>,
        ]}
      >
        <p>
          <IntlMessage id="general.modal.sessionexpire.desc" />
        </p>
        <p>
          <IntlMessage id="general.modal.sessionexpire.desc2" />
        </p>
        <p>
          <IntlMessage id="general.modal.sessionexpire.descdelay" />{" "}
          <Text strong>{TIMING}</Text>{" "}
          <IntlMessage id="general.modal.sessionexpire.secones" />.
        </p>
      </Modal>
    </Header>
  );
};

const mapStateToProps = ({ theme, pdpakit, auth }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    showContact,
    hideSidebar,
  } = theme;
  const { Username, refresh_token, email } = auth;
  const { domain, select_key } = pdpakit;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    domain,
    select_key,
    Username,
    showContact,
    hideSidebar,
    refresh_token,
    email,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
  setSelectKey,
  showContactChange,
  authenticated,
  signOut,
})(HeaderNav);
