import {
    SET_FORM_UPLOAD_IMAGE,
    SET_ADDRESS_COMPONENTS,
    SET_GOOGLE_MAP_FIELDS
  } from "redux/constants/Formtd";
  
  const initState = {
      form_upload_image: [],
      address_component: [],
      google_map_form_value: {}
  };
    
  const Formtd = (state = initState, action) => {
      const { type, payload } = action;
      switch (type) {
        case SET_FORM_UPLOAD_IMAGE: {
          return {
            ...state,
            form_upload_image: payload
          };
        }
        case SET_ADDRESS_COMPONENTS: {
          return {
            ...state,
            address_component: payload
          };
        }
        case SET_GOOGLE_MAP_FIELDS: {
          return {
            ...state,
            google_map_form_value: payload
          };
        }
        default: {
          return {
            ...state,
          };
        }
      }
  };
    
  export default Formtd;