import {
  GET_ALL_USER,
  SET_ALL_USER,
  LIST_PERSONNEL,
  LIST_USERS,
  SET_LIST_USERS,
} from "../constants/Orgs";

export const listPersonnel = () => {
  return {
    type: LIST_PERSONNEL,
  };
};

export const getUser = (data) => {
  return {
    type: GET_ALL_USER,
    payload: data,
  };
};

export const setUser = (data) => {
  return {
    type: SET_ALL_USER,
    payload: { ...data },
  };
};

export const listUsers = () => {
  return {
    type: LIST_USERS,
  };
};

export const setListUsers = (data) => {
  return {
    type: SET_LIST_USERS,
    payload: data,
  };
};
