import styled from "styled-components";
import PropTypes from "prop-types";
export const CircleBackgroundIcon = styled.div`
  ${({ circlesize, fontsize }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    circlesize &&
    ` min-width:${circlesize}px;
      min-height:${circlesize}px;
      background: rgb(224, 224, 224);`
  }
  border-radius: 50%;
  & .anticon {
    font-size: ${fontsize}px;
    color: black;
  }`}
`;

CircleBackgroundIcon.PropTypes = {
  circlesize: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  fontsize: PropTypes.number,
};
CircleBackgroundIcon.defaultProps = {
  circlesize: 20,
  fontsize: 10,
};
