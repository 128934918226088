import {
  CREATE_WEBFORM,
  SET_WEBFORM_DATA,
  LIST_WEBFORM,
  SET_LIST_WEBFORM,
  DELETE_WEBFORM,
  UPDATE_WEBFORM,
  LIST_WORKFLOW,
  CREATE_WORKFLOW,
  SET_LIST_WORKFLOW,
  SET_WORKFLOW_DATA,
  DELETE_WORKFLOW,
  CHANGE_REQUEST_DATA,
  LIST_REQUEST,
  SET_LIST_REQUEST,
  LIST_SUBTASK,
  SET_LIST_SUBTASK,
  CREATE_REQUEST,
  DELETE_REQUEST,
  CLOSE_REQUEST,
  REJECT_REQUEST,
  CREATE_SUBTASK,
  DELETE_SUBTASK,
  UPDATE_SUBTASK,
  SET_UPDATE_SUBTASK,
  UPDATE_WORKFLOW,
  LIST_ACT_REQUEST,
  CHANGE_LOADING,
  CHANGE_LOADER,
  CHANGE_RELOAD,
  ACTION_LOADING,
  SET_LIST_WORKFLOW_DATA_BYID,
  GET_LIST_WORKFLOW_BYID,
  ERROR_CASE_NOTI,
  UPDATE_WORKFLOW_LOADING,
  DELETE_SUBTASK_IN_WORKFLOW,
  CALL_BACK_EVENT
} from "../constants/Dsar";

export const createWebForm = (data) => {
  return {
    type: CREATE_WEBFORM,
    payload: data,
  };
};

export const setWebFormData = (data) => {
  return {
    type: SET_WEBFORM_DATA,
    payload: data,
  };
};

export const listWebForm = (data) => {
  return {
    type: LIST_WEBFORM,
    payload: data,
  };
};

export const setListWebForm = (data) => {
  return {
    type: SET_LIST_WEBFORM,
    payload: data,
  };
};

export const deleteWebForm = (ObjectUUID) => {
  return {
    type: DELETE_WEBFORM,
    payload: ObjectUUID,
  };
};

export const updateWebForm = (data) => {
  return {
    type: UPDATE_WEBFORM,
    payload: data,
  };
};

export const listWorkFlow = () => {
  return {
    type: LIST_WORKFLOW,
  };
};

export const setListWorkFlow = (data) => {
  return {
    type: SET_LIST_WORKFLOW,
    payload: data,
  };
};

export const getListWorkFlowByID = (data) => {
  return {
    type: GET_LIST_WORKFLOW_BYID,
    payload: data,
  };
};

export const setListWorkFlowDataByID = (data) => {
  return {
    type: SET_LIST_WORKFLOW_DATA_BYID,
    payload: data,
  };
};

export const createWorkFlow = (data) => {
  return {
    type: CREATE_WORKFLOW,
    payload: data,
  };
};

export const setWorkFlowData = (data) => {
  return {
    type: SET_WORKFLOW_DATA,
    payload: data,
  };
};

export const deleteWorkFlow = (data) => {
  return {
    type: DELETE_WORKFLOW,
    payload: data,
  };
};

export const changeRequestData = (data) => {
  return {
    type: CHANGE_REQUEST_DATA,
    payload: data,
  };
};

export const listRequest = () => {
  return {
    type: LIST_REQUEST,
  };
};

export const setListRequest = (data) => {
  return {
    type: SET_LIST_REQUEST,
    payload: data,
  };
};

export const listSubtask = () => {
  return {
    type: LIST_SUBTASK,
  };
};

export const setListSubtask = (subtask) => {
  return {
    type: SET_LIST_SUBTASK,
    payload: subtask,
  };
};

export const createRequest = (data) => {
  return {
    type: CREATE_REQUEST,
    payload: data,
  };
};

export const deleteRequest = (request_id) => {
  return {
    type: DELETE_REQUEST,
    payload: request_id,
  };
};

export const createSubtask = (data) => {
  return {
    type: CREATE_SUBTASK,
    payload: data,
  };
};

export const deleteSubtask = (subtask_id) => {
  return {
    type: DELETE_SUBTASK,
    payload: subtask_id,
  };
};

export const deleteSubtaskInWorkflow = (subtask_id) => {
  return {
    type: DELETE_SUBTASK_IN_WORKFLOW,
    payload: subtask_id,
  };
};

export const updateSubtask = (data) => {
  return {
    type: UPDATE_SUBTASK,
    payload: data,
  };
};

export const setUpdateSubtask = (data) => {
  return {
    type: SET_UPDATE_SUBTASK,
    payload: data,
  };
};

export const updateWorkflow = (data) => {
  return {
    type: UPDATE_WORKFLOW,
    payload: data,
  };
};

export const listActivityInRequest = (request_id) => {
  return {
    type: LIST_ACT_REQUEST,
    payload: request_id,
  };
};

export const closeRequest = (request_id) => {
  return {
    type: CLOSE_REQUEST,
    payload: request_id,
  };
};

export const rejectRequest = (request_id) => {
  return {
    type: REJECT_REQUEST,
    payload: request_id,
  };
};

export const changeLoading = (state) => {
  return {
    type: CHANGE_LOADING,
    payload: state,
  };
};

export const changeLoader = (state) => {
  return {
    type: CHANGE_LOADER,
    payload: state,
  };
};

export const changeReload = (state) => {
  return {
    type: CHANGE_RELOAD,
    payload: state,
  };
};

export const setActionLoading = (data) => {
  return {
    type: ACTION_LOADING,
    payload: data,
  };
};

export const setErrorCaseMessage = (data) => {
  return {
    type: ERROR_CASE_NOTI,
    payload: data,
  };
};

export const updateWorkflowLoading = (data) => {
  return {
    type: UPDATE_WORKFLOW_LOADING,
    payload: data,
  };
};

export const callBackEvent = (data) => {
  return {
    type: CALL_BACK_EVENT,
    payload: data,
  };
};
