import axios from "axios";
import { DSAR_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import {
  ENTRY_ROUTE,
  TOKEN_PAYLOAD_KEY,
  PUBLIC_REQUEST_KEY,
} from "configs/AppConfig";

const service = axios.create({
  baseURL: DSAR_BASE_URL,
  timeout: 60000,
});

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const store = JSON.parse(localStorage.getItem("store"));
    const jwtToken = store["auth"]["token"];

    // const jwtToken = "eyJraWQiOiJDVjFPWXdqa1wvYlk2SHQyY2FGMnVrbWRjaGxwR3VRVlViTGV3d3VkbVVIYz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJiNTg5OGFmNS1iYjU5LTQzYzEtODA4MS1hNDgyMjU3YjNkNTUiLCJjb2duaXRvOmdyb3VwcyI6WyJhYmNkIl0sImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY3VzdG9tOm9yZ2FuaXNhdGlvbiI6IlwvZ2VuZXJhbFwvdXNlciIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1zb3V0aGVhc3QtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aGVhc3QtMV9lR1FOWFB2c2ciLCJjb2duaXRvOnVzZXJuYW1lIjoiYjU4OThhZjUtYmI1OS00M2MxLTgwODEtYTQ4MjI1N2IzZDU1Iiwib3JpZ2luX2p0aSI6IjAzZGYwMzkyLWVkZGMtNDk0Yy04MDE2LWMzZWNmY2FiNzJhMiIsImF1ZCI6IjRzMnUxYTNnMjVxMTducnA5YmdjZTRpOTZmIiwiZXZlbnRfaWQiOiJiOGJiZmNjMS0wN2RiLTRlODctOWU4OS1kNGRlNGExNWMwYTkiLCJjdXN0b206dXNlcm5hbWUiOiJMZW9uaWRhcyBJIDEyMzEyMzEyMzEyYXNkYXNkYXNkYXNkIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MzgxNzY0NzEsImV4cCI6MTYzODI2Mjg3MSwiY3VzdG9tOnJvbGUiOiJVc2VyIiwiaWF0IjoxNjM4MTc2NDcxLCJmYW1pbHlfbmFtZSI6IlwvZ2VuZXJhbFwvdXNlclwvMGFhNGY5YzctMjEzMy00NGI1LWE3M2QtZTliM2YzYTRlMzQwIiwianRpIjoiOGUyMjU4NWMtMWQxZS00NmExLWI4MGEtMGY1MzY2YmZkNzUxIiwiZW1haWwiOiJiZW56YmVuejkwMEBnbWFpbC5jb20ifQ.vuO1PRtFunHihBuLQpneBjjFRhiealqzAps36Ey3Luw7fA2EI7nDY4SU08LmiQAZTdXJHKZBKl-Mm9jZVawJSmoH3hxNNENECKpmfcTVRbz21D2ad-1Ea0mevNCdf2nVBvfA8byjAP4OZCc0ldsTWzo8Ba-eg4djci5C-GMqNXyw2g8E47_H7cGwGa5hn0PD7rjD5yzDQgr_IdMxUMZMACb0LicZ2UmO2RS5EDjFSx5-z7U4KhdnkgH7rL7S8mC6sJ85jjZuXGIhERNa4-85sFCKVMqiCPYvhN9_AprKIRCFn-tz40iMTmIUm0nLNLTmSzpT1tW1AtqRTajrG7mfIQ"

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error).then();
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Remove token and redirect
    if (
      error.response.status === 400 ||
      error.response.status === 403 ||
      error.response.status === 408
    ) {
      localStorage.removeItem(AUTH_TOKEN);
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }
    return Promise.reject(error.response.data);
  }
);

export default service;
