import {
  SET_LOADING_LIST,
  SET_LIST_REQUEST_PORTAL,
  COMMENT_TRIGGER,
} from "redux/constants/Portal";

const initState = {
  list_request_portal: [],
  loading_list: false,
  comment_trigger: false,
};

const Portal = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LIST_REQUEST_PORTAL: {
      const clone_payload = [...payload];
      // const result_status = clone_payload.map(
      //   (data) => (data.status = "pending")
      // );
      // clone_payload.map((data) => (data.status = "pending"));
      // clone_payload.map((data)=>console.log(data))
      return {
        ...state,
        list_request_portal: payload,
      };
    }
    case SET_LOADING_LIST: {
      return {
        ...state,
        loading_list: payload,
      };
    }
    case COMMENT_TRIGGER: {
      return {
        ...state,
        comment_trigger: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default Portal;
