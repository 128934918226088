import antdEnUS from "antd/es/locale/th_TH";
import thMsg from "../locales/th_TH.json";

const ThLang = {
  antd: antdEnUS,
  locale: "th-TH",
  messages: {
    ...thMsg,
  },
};
export default ThLang;
