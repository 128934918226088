import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { connect } from "react-redux";
import { authenticated } from "../../redux/actions/Auth";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}`}
          component={lazy(() => import(`views/app-views/apps`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}`} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = ({ auth }) => {
  const { refresh_token, token } = auth;
  return { refresh_token, token };
};

export default React.memo(
  connect(mapStateToProps, { authenticated })(AppViews)
);
