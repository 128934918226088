// import React from "react";
// import { Switch, Route } from "react-router-dom";
// import AuthViews from "views/auth-views";
// import Loading from "components/shared-components/Loading";
// import { useThemeSwitcher } from "react-css-theme-switcher";

// export const AuthLayout = () => {
//   const { status } = useThemeSwitcher();

//   if (status === "loading") {
//     return <Loading cover="page" />;
//   }

//   return (
//     <div className="auth-container">
//       <Switch>
//         <Route path="" component={AuthViews} />
//       </Switch>
//     </div>
//   );
// };

// export default AuthLayout;
import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthViews from "views/auth-views";
import Loading from "components/shared-components/Loading";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Layout, Grid } from "antd";
import HeaderNav from "components/landding-page-component/HeaderNav";
import utils from "utils";
import { connect } from "react-redux";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import styled from "styled-components";
const { useBreakpoint } = Grid;
const { Content } = Layout;
export const AuthLayout = (props) => {
  const { isCenterLayout = false } = props;
  const { status } = useThemeSwitcher();
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  React.useEffect(() => {
    console.log(isCenterLayout);
  }, [isCenterLayout]);

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <>
      {isCenterLayout && (
        <div className="auth-container">
          <Switch>
            <Route path="" component={AuthViews} />
          </Switch>
        </div>
      )}
      {!isCenterLayout && (
        <Layout>
          <HeaderNav isMobile={isMobile} />
          <Layout className="app-container ">
            <Layout className="app-layout">
              <AppChangeBackGroundTheme className={`app-content  `}>
                <Content className="content-full">
                  <Switch>
                    <Route path="" component={AuthViews} />
                  </Switch>
                </Content>
              </AppChangeBackGroundTheme>
              <img
                src={`/img/new-logo.png`}
                alt=""
                style={{
                  width: "10%",
                  margin: "0px 0px 10px 1.8%",
                }}
              />
              <Footer />
            </Layout>
          </Layout>
        </Layout>
      )}
    </>
  );
};
const mapStateToProps = ({ auth }) => {
  const { isCenterLayout } = auth;
  return { isCenterLayout };
};

export default connect(mapStateToProps)(AuthLayout);

const AppChangeBackGroundTheme = styled.div`
  &::before {
    content: "";
    background-image: url(/img/background-default-light-logo.png);
    background-size: 100% 100%;
    display: inline-block;
    height: 330.195px;
    width: 332px;
    position: absolute;
    bottom: 50px;
    right: 25px;
    z-index: -1000;
  }
`;
