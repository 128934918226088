import React from "react";
import styled from "styled-components";
import { FacebookFilled, PhoneFilled } from "@ant-design/icons";
import { lineIcon } from "assets/svg/iconantdcustom";
import IconCustom from "@ant-design/icons";
export const FloatingTools = (props) => {
  const { children } = props;
  const FloatingToolsStyle = styled.div`
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    position: fixed;
    right: 0;
    top: 20%;
    z-index: 9;
    & {
      a {
        -webkit-box-align: center;
        -webkit-box-pack: center;
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 20px;
        height: 42px;
        justify-content: center;
        width: 42px;
        transition: all 0.15s linear 0.01s;
        & + a {
          margin-top: 2px;
        }
        &:active {
          outline: 0;
        }

        &:hover {
          transform: scale(1.1);
          text-decoration: underline;
        }

        &:focus {
          outline: 5px;
          outline-offset: -2px;
        }
        &:not([href]):hover {
          cursor: default;
        }
      }
    }

    ::selection {
      background-color: #339fd6;
      color: #fff;
      text-shadow: none;
    }

    .float-facebook {
      background-color: #3b5998;
    }

    .float-twitter {
      background-color: #1da1f2;
    }
    .float-line {
      background-color: #66c53f;
    }

    body > div:first-child > div {
      height: 100%;
    }

    html :where([style*="border-width"]) {
      border-style: solid;
    }

    html :where(img[class*="wp-image-"]) {
      height: auto;
      max-width: 100%;
    }

    :where(.wp-block-columns.has-background) {
      padding: 1.25em 2.375em;
    }

    :focus {
      outline: transparent !important;
    }

    .no-underline {
      text-decoration: none !important;
    }
  `;
  return (
    <>
      <FloatingToolsStyle className="floating-tool inherited-styles-for-exported-element">
        {children}
      </FloatingToolsStyle>
    </>
  );
};
