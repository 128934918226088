import React from "react";
import { connect } from "react-redux";
import WebformViews from "views/webform-views";
import { Layout, Divider } from "antd";

import Flex from "components/shared-components/Flex";
import NavLanguage from "components/layout-components/NavLanguage";
import HeaderNav from "components/layout-components/HeaderNav";

const { Content } = Layout;

const WebformLayout = (props) => {
  const x = window.location.pathname.split('/')

  const NavHeader = () => {
    return x[2] === "preview" ? (
      <HeaderNav isMobile={false} isOnlyLogo={true} />
    ) : (
      <div style={{
        width: '100%',
        display: 'flex',
        marginTop: '20px',
        padding: '0 20px',
        alignItems: 'flex-end',
        flexDirection: 'column'
      }}>
        <NavLanguage configDisplay={true} />
      </div>
    )
  }

  const Footer = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: "5em", marginBottom: "2em" }}>
        <Divider style={{ borderColor: '#ccc' }} />
        <p style={{ fontSize: 16, marginBottom: 5 }}>สถานีตำรวจนครบาลทองหล่อ</p>
        <p style={{ marginBottom: 5 }}>เลขที่ 800 ถนนสุขุมวิท ซอยสุขุมวิท 55 แขวงคลองตันเหนือ เขตวัฒนา กทม. 10110</p>  
        <p>โทร 02 381 8853</p>
      </div>
    )
  }

  return (
    <Layout className="app-container" style={{ backgroundColor: '#fff' }}>
      <NavHeader />
      <Layout className="app-layout" style={{ marginTop: x[2] === "preview" ? 50 : 0 }}>
        <div style={{ width: 800, margin: '30px auto 0', padding: 0 }} className={`app-content layout-top-nav`}>
          <Content>
            <WebformViews />
          </Content>
          {/* <Footer /> */}
        </div>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, hideSidebar, navType, locale } = theme;
  return { navCollapsed, hideSidebar, navType, locale };
};

export default connect(mapStateToProps)(React.memo(WebformLayout));