import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Pdpakit from "./Pdpakit";
import Dsar from "./Dsar";
import Orgs from "./Orgs";
import Portal from "./Portal";

export default function* rootSaga() {
  yield all([Auth(), Pdpakit(), Dsar(), Orgs(), Portal()]);
}
