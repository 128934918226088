export const SIGNIN = "SIGNIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const AUTH_TOKEN = "auth_token";
export const REFRESH_TOKEN = "refresh_token";
export const ACCESS_TOKEN = "access_token";
export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const SET_CHANGE_PROFILE = "SET_CHANGE_PROFILE";
export const AUTH_CENTER_LAYOUT = "AUTH_CENTER_LAYOUT";
export const INFO_PERSON_PROFILE = "INFO_PERSON_PROFILE";
