import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  GET_USER,
  SET_USER,
  CHANGE_PROFILE,
  SET_CHANGE_PROFILE,
  REFRESH_TOKEN,
  HIDE_LOADING,
  AUTH_CENTER_LAYOUT,
  INFO_PERSON_PROFILE,
} from "../constants/Auth";

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    data: token,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

export const getUser = (AccessToken) => {
  return {
    type: GET_USER,
    payload: AccessToken,
  };
};

export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  };
};

export const changeProfile = (profile) => {
  return {
    type: CHANGE_PROFILE,
    payload: profile,
  };
};

export const setChangeProfile = (profile) => {
  return {
    type: SET_CHANGE_PROFILE,
    payload: profile,
  };
};

export const refreshToken = (refresh_token) => {
  return {
    type: REFRESH_TOKEN,
    payload: refresh_token,
  };
};

export const centerLayout = (isCenter) => {
  return {
    type: AUTH_CENTER_LAYOUT,
    payload: isCenter,
  };
};

export const infoPerson = (infoProfile) => {
  return {
    type: INFO_PERSON_PROFILE,
    payload: infoProfile,
  };
};
