import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const pageOnlyLight = [
  "/home/menu",
  "/home/form-select",
  "verification/",
  "form/",
];
const filterList = pageOnlyLight.filter((ez) =>
  window.location.pathname.includes(ez)
);
const coreTheme = filterList.length > 0 ? "light" : THEME_CONFIG.currentTheme;

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={coreTheme}
          insertionPoint="styles-insertion-point"
        >
          <Router
            getUserConfirmation={(message, callback) => {
              // this is the default behavior
              const allowTransition = window.confirm(message);
              callback(allowTransition);
            }}
          >
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>

      <NotificationContainer />
    </div>
  );
}

export default App;
