import fetch from "axios/FetchOrg";

const OrgService = {};

OrgService.orgUserList = async (PaginationToken = null) =>
  await fetch({
    method: "post",
    url: "/orgizations/user/lists",
    data: { PaginationToken },
  })
    .then((resp) => resp)
    .catch((err) => err);

OrgService.listUser = async (data) =>
  await fetch({
    method: "post",
    url: "/orgizations/user/lists",
    data: { ...data },
  })
    .then((resp) => resp)
    .catch((err) => err);

export default OrgService;
