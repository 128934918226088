import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { PREVIEW_PREFIX_PATH } from "configs/AppConfig";

export const PreviewViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${PREVIEW_PREFIX_PATH}/banner-1`}
          component={lazy(() => import(`./banner-1`))}
        />
        <Route
          path={`${PREVIEW_PREFIX_PATH}/icon-1`}
          component={lazy(() => import(`./icon-1`))}
        />
        <Route
          path={`${PREVIEW_PREFIX_PATH}/perf-1`}
          component={lazy(() => import(`./perf-1`))}
        />
        <Route
          path={`${PREVIEW_PREFIX_PATH}/perf-2`}
          component={lazy(() => import(`./perf-2`))}
        />
        <Redirect
          from={`${PREVIEW_PREFIX_PATH}`}
          to={`${PREVIEW_PREFIX_PATH}/banner-1`}
        />
      </Switch>
    </Suspense>
  );
};

export default PreviewViews;
