import {
  GET_LIST_DOMAIN,
  SET_LIST_DOMAIN,
  GET_COOKIES,
  SET_COOKIES,
  DELETE_DOMAIN,
  SET_DELETE_DOMAIN,
  ADD_DOMAIN,
  SET_ADD_DOMAIN,
  UPDATE_DOMAIN,
  SET_UPDATE_DOMAIN,
  GET_CONSENT,
  SET_CONSENT,
  SCAN_COOKIES,
  SET_SELECT_KEY,
  SET_BANNER,
  UPDATE_CONFIG_COLUMN,
  UPDATE_COOKIES_CATEGORY,
  SET_COOKIES_CATEGORY,
  SET_LOADING,
  SET_COOKIES_SELECT,
  EDIT_COOKIES_SELECT,
  GET_POLICY_META,
  SET_POLICY_META,
  SET_POLICY_DATA,
  CREATE_POLICY,
  LIST_POLICY,
  SET_POLICY,
  DELETE_POLICY,
  SET_DELETE_POLICY,
  SET_POLICY_DETAIL,
  GET_POLICY_DETAIL,
  UPDATE_POLICY,
  SET_LOADING_ACTION,
  SET_FOCUS_TITLE,
  GET_POLICY_VERSION,
  SET_POLICY_VERSION,
} from "../constants/Pdpakit";

export const getListDomain = () => {
  return {
    type: GET_LIST_DOMAIN,
  };
};

export const setListDomain = (domain) => {
  return {
    type: SET_LIST_DOMAIN,
    payload: domain,
  };
};

export const addDomain = (data) => {
  return {
    type: ADD_DOMAIN,
    payload: data,
  };
};

export const setAddDomain = (domain) => {
  return {
    type: SET_ADD_DOMAIN,
    payload: domain,
  };
};

export const updateDomain = (domain) => {
  return {
    type: UPDATE_DOMAIN,
    payload: domain,
  };
};

export const setUpdateDomain = (domain) => {
  return {
    type: SET_UPDATE_DOMAIN,
    payload: domain,
  };
};

export const deleteDomain = (key) => {
  return {
    type: DELETE_DOMAIN,
    payload: key,
  };
};

export const setDeleteDomain = (key) => {
  return {
    type: SET_DELETE_DOMAIN,
    payload: key,
  };
};

export const getCookies = (key) => {
  return {
    type: GET_COOKIES,
    payload: key,
  };
};

export const setCookies = (domain) => {
  return {
    type: SET_COOKIES,
    payload: domain,
  };
};

export const getConsent = (key) => {
  return {
    type: GET_CONSENT,
    payload: key,
  };
};

export const setConsent = (data) => {
  return {
    type: SET_CONSENT,
    payload: data,
  };
};

export const scanCookies = (key) => {
  return {
    type: SCAN_COOKIES,
    payload: key,
  };
};

export const setSelectKey = (key) => {
  return {
    type: SET_SELECT_KEY,
    payload: key,
  };
};

export const setBanner = (data) => {
  return {
    type: SET_BANNER,
    payload: data,
  };
};

export const updateConfigColumn = (data) => {
  let item = [...data];
  localStorage.setItem("cookies_column", JSON.stringify(item));
  return {
    type: UPDATE_CONFIG_COLUMN,
    payload: data,
  };
};

export const updateCookiesCategory = (data) => {
  return {
    type: UPDATE_COOKIES_CATEGORY,
    payload: data,
  };
};

export const setCookiesCategory = (data) => {
  return {
    type: SET_COOKIES_CATEGORY,
    payload: data,
  };
};

export const setLoading = (boolean) => {
  return {
    type: SET_LOADING,
    payload: boolean,
  };
};

export const setLoadingAction = (boolean) => {
  return {
    type: SET_LOADING_ACTION,
    payload: boolean,
  };
};

export const setCookiesSelect = (cookies) => {
  return {
    type: SET_COOKIES_SELECT,
    payload: cookies,
  };
};

export const editCookiesSelect = (cookies) => {
  return {
    type: EDIT_COOKIES_SELECT,
    payload: cookies,
  };
};

export const getPolicyMeta = () => {
  return {
    type: GET_POLICY_META,
  };
};

export const setPolicyMeta = (meta) => {
  return {
    type: SET_POLICY_META,
    payload: meta,
  };
};

export const setPolicyData = (policy) => {
  return {
    type: SET_POLICY_DATA,
    payload: policy,
  };
};

export const createPolicy = (data) => {
  return {
    type: CREATE_POLICY,
    payload: data,
  };
};

export const listPolicy = () => {
  return {
    type: LIST_POLICY,
  };
};

export const setPolicy = (policy) => {
  return {
    type: SET_POLICY,
    payload: policy,
  };
};

export const getPolicyDetail = (data) => {
  return {
    type: GET_POLICY_DETAIL,
    payload: data,
  };
};

export const setPolicyDetail = (data) => {
  return {
    type: SET_POLICY_DETAIL,
    payload: data,
  };
};

export const deletePolicy = (key) => {
  return {
    type: DELETE_POLICY,
    payload: key,
  };
};

export const setDeletePolicy = (key) => {
  return {
    type: SET_DELETE_POLICY,
    payload: key,
  };
};

export const updatePolicy = (data) => {
  return {
    type: UPDATE_POLICY,
    payload: data,
  };
};

export const setFocusTitle = (data) => {
  return {
    type: SET_FOCUS_TITLE,
    payload: data,
  };
};

export const getPolicyVersion = (key) => {
  return {
    type: GET_POLICY_VERSION,
    payload: key,
  };
};

export const setPolicyVersion = (data) => {
  return {
    type: SET_POLICY_VERSION,
    payload: data,
  };
};
