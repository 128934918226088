import {
  SET_WEBFORM_DATA,
  SET_LIST_WEBFORM,
  SET_LIST_WORKFLOW,
  SET_WORKFLOW_DATA,
  CHANGE_REQUEST_DATA,
  SET_LIST_REQUEST,
  CHANGE_SUBTASK_DATA,
  SET_LIST_SUBTASK,
  CHANGE_LOADING,
  CHANGE_LOADER,
  CHANGE_RELOAD,
  SET_UPDATE_SUBTASK,
  ACTION_LOADING,
  SET_LIST_WORKFLOW_DATA_BYID,
  ERROR_CASE_NOTI,
  UPDATE_WORKFLOW_LOADING,
  CALL_BACK_EVENT
} from "../constants/Dsar";

const initState = {
  webform_data: {},
  workflow_data: {},
  request_data: {},
  subtask_data: {},
  list_workflow: null,
  list_workflow_byid: null,
  list_request: [],
  list_subtask: [],
  loader: false,
  reload: false,
  loading: false,
  action_loading: false,
  error_message: { enable: false, text: "" },
  update_workflow_loading: false,
  call_back_event: { type: "pending", msg: "" }
};

const Dsar = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_WEBFORM_DATA: {
      return {
        ...state,
        webform_data: payload,
      };
    }
    case SET_LIST_WEBFORM: {
      return {
        ...state,
        list_webform: payload,
        loader: false,
        reload: false,
        loading: false,
      };
    }
    case SET_LIST_WORKFLOW: {
      return {
        ...state,
        list_workflow: payload,
        loader: false,
        reload: false,
        loading: false,
      };
    }
    case SET_LIST_WORKFLOW_DATA_BYID: {
      return {
        ...state,
        list_workflow_byid: payload,
      };
    }
    case SET_WORKFLOW_DATA: {
      return {
        ...state,
        workflow_data: payload,
      };
    }
    case CHANGE_REQUEST_DATA: {
      return {
        ...state,
        request_data: payload,
      };
    }
    case SET_LIST_REQUEST: {
      return {
        ...state,
        list_request: payload,
        loader: false,
        reload: false,
        loading: false,
      };
    }
    case CHANGE_SUBTASK_DATA: {
      return {
        ...state,
        subtask_data: payload,
      };
    }
    case SET_LIST_SUBTASK: {
      return {
        ...state,
        list_subtask: payload,
      };
    }
    case SET_UPDATE_SUBTASK: {
      const index = state.list_subtask.items.findIndex(
        (e) => e.ObjectUUID === payload.ObjectUUID
      );
      let list_subtask = state.list_subtask;
      list_subtask.items[index] = { ...payload };
      return {
        ...state,
        list_subtask,
      };
    }
    case CHANGE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case CHANGE_LOADER: {
      return {
        ...state,
        loader: payload,
      };
    }
    case CHANGE_RELOAD: {
      return {
        ...state,
        reload: payload,
      };
    }
    case ACTION_LOADING: {
      return {
        ...state,
        action_loading: payload,
      };
    }
    case ERROR_CASE_NOTI: {
      return {
        ...state,
        error_message: payload,
      };
    }
    case UPDATE_WORKFLOW_LOADING: {
      return {
        ...state,
        update_workflow_loading: payload,
      };
    }
    case CALL_BACK_EVENT: {
      return {
        ...state,
        call_back_event: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default Dsar;
