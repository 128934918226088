import fetch from "axios/FetchFeedback";
import ses from "axios/FetchSimpleEmailService";

const FeedbackService = {};

FeedbackService.customer = async (data) =>
  await ses({ method: "post", url: "/customer", data: { data } })
    .then((resp) => resp)
    .catch((err) => err);

FeedbackService.checkAlreadyEmail = async (email) =>
  await fetch({ method: "get", url: `/update-package-user?email=${email}` })
    .then((resp) => resp)
    .catch((err) => err);

FeedbackService.stampSendEmail = async (email) =>
  await fetch({ method: "post", url: "/update-package-user", data: { email } })
    .then((resp) => resp)
    .catch((err) => err);

export default FeedbackService;
