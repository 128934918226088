import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { GET_ALL_USER } from "../constants/Orgs";
import { setUser } from "../actions/Orgs";
import { LIST_USERS } from "redux/constants/Orgs";
import { setListUsers } from "redux/actions/Orgs";
import OrgService from "services/OrgService";

export function* getUsers() {
  yield takeEvery(GET_ALL_USER, function* ({ payload }) {
    try {
      const resp = yield call(OrgService.listUser, payload);
      if (resp.statusCode === 200) {
        yield put(setUser(resp));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* listUsers() {
  yield takeEvery(LIST_USERS, function* () {
    try {
      const resp = yield call(OrgService.orgUserList);
      if (resp.statusCode === 200) {
        yield put(setListUsers(resp.users));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getUsers), fork(listUsers)]);
}
