export const CREATE_WEBFORM = "CREATE_WEBFORM";
export const SET_WEBFORM_DATA = "SET_WEBFORM_DATA";
export const LIST_WEBFORM = "LIST_WEBFORM";
export const SET_LIST_WEBFORM = "SET_LIST_WEB_FORM";
export const DELETE_WEBFORM = "DELETE_WEBFORM";
export const UPDATE_WEBFORM = "UPDATE_WEBFORM";

export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const LIST_WORKFLOW = "LIST_WORKFLOW";
export const SET_LIST_WORKFLOW = "SET_LIST_WORKFLOW";
export const GET_LIST_WORKFLOW_BYID = "GET_LIST_WORKFLOW_BYID";
export const SET_LIST_WORKFLOW_DATA_BYID = "SET_LIST_WORKFLOW_BYID";
export const SET_WORKFLOW_DATA = "SET_WORKFLOW_DATA";
export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW";
export const ACTION_LOADING = "ACTION_LOADING";
export const UPDATE_WORKFLOW_LOADING = "UPDATE_WORKFLOW_LOADING";
export const DELETE_SUBTASK_IN_WORKFLOW = "DELETE_SUBTASK_IN_WORKFLOW";

export const CHANGE_REQUEST_DATA = "CHANGE_REQUEST_DATA";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const LIST_REQUEST = "LIST_REQUEST";
export const SET_LIST_REQUEST = "SET_LIST_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const LIST_ACT_REQUEST = "LIST_ACT_REQUEST";
export const CLOSE_REQUEST = "CLOSE_REQUEST";
export const SET_CLOSE_REQUEST = "SET_CLOSE_REQUEST";
export const REJECT_REQUEST = "REJECT_REQUEST";
export const SET_REJECT_REQUEST = "SET_REJECT_REQUEST";

export const CHANGE_SUBTASK_DATA = "CHANGE_SUBTASK_DATA";
export const LIST_SUBTASK = "LIST_SUBTASK";
export const SET_LIST_SUBTASK = "SET_LIST_SUBTASK";
export const CREATE_SUBTASK = "CREATE_SUBTASK";
export const DELETE_SUBTASK = "DELETE_SUBTASK";
export const UPDATE_SUBTASK = "UPDATE_SUBTASK";
export const SET_UPDATE_SUBTASK = "SET_UPDATE_SUBTASK";

export const CHANGE_LOADING = "CHANGE_LOADING";
export const CHANGE_LOADER = "CHANGE_LOADER";
export const CHANGE_RELOAD = "CHANGE_RELOAD";

export const ERROR_CASE_NOTI = "ERROR_CASE_NOTI";
export const CALL_BACK_EVENT = "CALL_BACK_EVENT";
