import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import PortalService from "services/PortalService";
import { LIST_REQUEST_PORTAL } from "redux/constants/Portal";
import { setListRequestPortal, setLoadingList } from "redux/actions/Portal";

export function* listRequestPortals() {
  yield takeEvery(LIST_REQUEST_PORTAL, function* () {
    yield put(setLoadingList(true));

    try {
      const resp = yield call(PortalService.getRequestPortal);

      if (resp.statusCode === 200) {
        yield put(setListRequestPortal(resp.data));
        yield put(setLoadingList(false));
      } else {
        yield put(setLoadingList(false));
      }
    } catch (err) {
      console.log(err);
      yield put(setLoadingList(false));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(listRequestPortals)]);
}
