import { VERIFY_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Row, Col, Card } from "antd";
import styled from "styled-components";
import Loading from "components/shared-components/Loading";
import { ImgSizeAuto } from "components/shared-components/ImgSizeAuto";
import PoweredByLogo from "assets/img/powered-by-logo.png";

const backgroundURL = "/img/others/img-17.jpg";

const EmailContainer = styled.div`
    background-image: url(${backgroundURL});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Centered = styled.div`
    ${({ mb }) => `
        text-align: center;
        margin-bottom: ${mb}px;
    `}
`;

const NavigateRoute = () => (
    <Suspense fallback={<Loading cover="content" />}>
        <Switch>
            <Route
                path={`${VERIFY_PREFIX_PATH}/request/:id`}
                component={lazy(() => import(`views/verification-views/email-request`))}
            />

            <Route
                path={`${VERIFY_PREFIX_PATH}/verify/:id/:email`}
                component={lazy(() => import(`views/verification-views/email-verify`))}
            />

            <Route
                path={`/home/menu`}
                component={lazy(() => import(`views/verification-views/home-menu`))}
            />

            <Route
                path={`/home/form-select/:id`}
                component={lazy(() => import(`views/verification-views/home-form`))}
            />
            <Redirect from="/home" to="/home/menu" />
        </Switch>
    </Suspense>
)

const EmailVerification = (props) => {
    const viewPath = props.location.pathname ? props.location.pathname.split('/')[1] : 'verification'
    const dirCol = {
        _xs: viewPath === 'verification' ? 7 : 16,
        _sm: viewPath === 'verification' ? 7 : 13,
        _md: viewPath === 'verification' ? 7 : 10,
        _lg: viewPath === 'verification' ? 7 : 7
    }

    useEffect(() => {
        localStorage.removeItem("form-data-viewer")
    }, [])

    return (
        <EmailContainer>
            <Row justify="center">
                <Col xs={dirCol._xs} sm={dirCol._sm} md={dirCol._md} lg={dirCol._lg}>
                    <Card style={{ marginBottom: 0 }}>
                        <Centered mb={24}>
                            <ImgSizeAuto src="/img/new-logo.png" width={170} alt="logo" />
                        </Centered>
                        
                        <NavigateRoute />
                    </Card>
                </Col>
            </Row>
        </EmailContainer>
    )
}

export default EmailVerification