export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSED_NAV";
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const SIDE_NAV_STYLE_CHANGE = "SIDE_NAV_STYLE_CHANGE";
export const NAV_TYPE_CHANGE = "NAV_TYPE_CHANGE";
export const TOP_NAV_COLOR_CHANGE = "TOP_NAV_COLOR_CHANGE";
export const HEADER_NAV_COLOR_CHANGE = "HEADER_NAV_COLOR_CHANGE";
export const TOGGLE_MOBILE_NAV = "TOGGLE_MOBILE_NAV";
export const SWITCH_THEME = "SWITCH_THEME";
export const DIRECTION_CHANGE = "DIRECTION_CHANGE";
export const SHOW_CONTACT_CHANGE = "SHOW_CONTACT_CHANGE";
export const HIDE_SIDEBAR_CHANGE = "HIDE_SIDEBAR_CHANGE";
