import fetch from "auth/FetchCognitoIdentity";

const JwtAuthService = {};

JwtAuthService.signIn = async (email, password) =>
  await fetch({ method: "post", url: "/signin", data: { email, password } })
    .then((resp) => resp)
    .catch((err) => err);

JwtAuthService.signUp = async (email, password) =>
  await fetch({ url: "/signup", method: "post", data: { email, password } })
    .then((resp) => resp)
    .catch((err) => err);

JwtAuthService.resendCode = (data) => {
  const { email } = data;
  return fetch({
    url: "/resendcode",
    method: "post",
    data: {
      email,
    },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

JwtAuthService.getForgotCode = (data) => {
  const { email } = data;
  return fetch({
    url: `/forgotpass`,
    method: "post",
    data: {
      email,
    },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

JwtAuthService.confirmForgotPassword = (data) => {
  const { code, email, password } = data;
  return fetch({
    url: "/forgotpass",
    method: "post",
    data: {
      code,
      email,
      password,
    },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

JwtAuthService.changePasswd = (data) => {
  const { AccessToken, PreviousPassword, ProposedPassword, email } = data;
  return fetch({
    url: "/changepasswd",
    method: "post",
    data: {
      email,
      AccessToken,
      PreviousPassword,
      ProposedPassword,
    },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

JwtAuthService.getUser = async (AccessToken) => {
  return await fetch({ method: "post", url: "/getuser", data: { AccessToken } })
    .then((resp) => resp)
    .catch((err) => err);
};

JwtAuthService.refreshToken = async (refresh_token) => {
  return await fetch({
    method: "post",
    url: "/userrefreshtoken",
    data: { data: { refresh_token } },
  })
    .then((resp) => resp)
    .catch((err) => err);
};

JwtAuthService.changeProfile = async (data) => {
  return await fetch({ method: "post", url: "/changeprofile", data })
    .then((resp) => resp)
    .catch((err) => err);
};

JwtAuthService.getFastPassURL = async (user) => {
  return await fetch({ method: "GET", url: `/fastpass?user=${user}` })
    .then((resp) => resp)
    .catch((err) => err);
};

export default JwtAuthService;
