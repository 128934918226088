export const GET_LIST_DOMAIN = "GET_LIST_DOMAIN";
export const SET_LIST_DOMAIN = "SET_LIST_DOMAIN";
export const ADD_DOMAIN = "ADD_DOMAIN";
export const SET_ADD_DOMAIN = "SET_ADD_DOMAIN";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const SET_DELETE_DOMAIN = "SET_DELETE_DOMAIN";
export const UPDATE_DOMAIN = "UPDATE_DOMAIN";
export const SET_UPDATE_DOMAIN = "SET_UPDATE_DOMAIN";
export const GET_COOKIES = "GET_COOKIES";
export const SET_COOKIES = "SET_COOKIES";
export const SCAN_COOKIES = "SCAN_COOKIES";
export const SET_COOKIES_SELECT = "SET_COOKIES_SELECT";
export const EDIT_COOKIES_SELECT = "EDIT_COOKIES_SELECT";
export const GET_CONSENT = "GET_CONSENT";
export const SET_CONSENT = "SET_CONSENT";
export const SET_SELECT_KEY = "SET_SELECT_KEY";
export const SET_BANNER = "SET_BANNER_SETTING";
export const UPDATE_CONFIG_COLUMN = "UPDATE_CONFIG_COLUMN";
export const UPDATE_COOKIES_CATEGORY = "UPDATE_COOKIES_CATEGORY";
export const SET_COOKIES_CATEGORY = "SET_COOKIES_CATEGORY";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_ACTION = "SET_LOADING_ACTION";

export const GET_POLICY_META = "GET_POLICY_META";
export const SET_POLICY_META = "SET_POLICY_META";
export const SET_POLICY_DATA = "SET_POLICY_DATA";
export const CREATE_POLICY = "CREATE_POLICY";
export const LIST_POLICY = "LIST_POLICY";
export const SET_POLICY = "SET_POLICY";
export const GET_POLICY_DETAIL = "GET_POLICY_DETAIL";
export const SET_POLICY_DETAIL = "SET_POLICY_DETAIL";
export const UPDATE_POLICY = "UPDATE_POLICY";
export const DELETE_POLICY = "DELETE_POLICY";
export const SET_DELETE_POLICY = "SET_DELETE_POLICY";
export const SET_FOCUS_TITLE = "SET_FOCUS_TITLE";
export const GET_POLICY_VERSION = "GET_POLICY_VERSION";
export const SET_POLICY_VERSION = "SET_POLICY_VERSION";
