import axios from "axios";
import { SES_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import {
  ENTRY_ROUTE,
  TOKEN_PAYLOAD_KEY,
  PUBLIC_REQUEST_KEY,
} from "configs/AppConfig";

const service = axios.create({
  baseURL: SES_BASE_URL,
  timeout: 60000,
});

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const authRawData = JSON.parse(localStorage.getItem("auth"));
    const jwtToken = authRawData["data"]["auth_token"];

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error).then();
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Remove token and redirect
    if (
      error.response.status === 400 ||
      error.response.status === 403 ||
      error.response.status === 408
    ) {
      localStorage.removeItem(AUTH_TOKEN);
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default service;
