import React from "react";

export default function Footer() {
  return (
    <footer className="footer" style={{
      alignItems: "center",
      justifyContent: "flex-start",
      margin: "0 1rem",
      fontSize: "0.7rem",
    }}>
      <span>
        &copy; {`${new Date().getFullYear()}`} UDONCOP by Security Pitch
      </span>
    </footer>
  );
}
