import {
  SET_LIST_DOMAIN,
  SET_COOKIES,
  SET_ADD_DOMAIN,
  SET_DELETE_DOMAIN,
  SET_UPDATE_DOMAIN,
  SET_CONSENT,
  SET_SELECT_KEY,
  SET_BANNER,
  UPDATE_CONFIG_COLUMN,
  SET_COOKIES_CATEGORY,
  SET_LOADING,
  SET_COOKIES_SELECT,
  EDIT_COOKIES_SELECT,
  SET_POLICY_META,
  SET_POLICY_DATA,
  SET_POLICY,
  SET_DELETE_POLICY,
  SET_POLICY_DETAIL,
  SET_LOADING_ACTION,
  SET_FOCUS_TITLE,
  SET_POLICY_VERSION,
} from "../constants/Pdpakit";

const COLUMN = JSON.parse(localStorage.getItem("cookies_column"));

export const init_data_collections = {
  financial_information: [
    {
      name: "otherwise",
      more: [],
    },
  ],
  technical_information: [
    {
      name: "otherwise",
      more: [],
    },
  ],
  personal_information: [
    {
      name: "otherwise",
      more: [],
    },
  ],
  contact_information: [
    {
      name: "otherwise",
      more: [],
    },
  ],
  other_personal_information: [
    {
      name: "otherwise",
      more: [],
    },
  ],
  third_party_authentication: [
    {
      name: "otherwise",
      more: [],
    },
  ],
  special_information: [
    {
      name: "otherwise",
      more: [],
    },
  ],
};

export const init_server_collections = {
  rent_server_provider_in_country: false,
  rent_server_provider_outside_country: false,
  server_in_country_located_in_organization: false,
  server_in_country_rented_provider_space: false,
  server_outside_country_located_in_organization: false,
  server_outside_country_rented_provider_space: false,
};

const domainStorageData = localStorage.getItem("domain")
  ? JSON.parse(localStorage.getItem("domain"))
  : { body: [] };

const initState = {
  domain: domainStorageData,
  banner: domainStorageData.body.length > 0 ? domainStorageData.body : [],
  cookies: [],
  policy: {
    list: false,
    policy_data: {
      name: "",
      lang: "th",
      company_type: [
        {
          default: true,
          name: "เรา",
          selected: true,
        },
        {
          default: false,
          name: "พวกเรา",
          selected: true,
        },
      ],
      company_info: {
        company_name: "",
        trade_name: "",
      },
      data_collections: init_data_collections,
      server_collections: init_server_collections,
      server_collections_more: [],
      data_admin: {
        name: "",
        email: "",
        address: "",
        phone_number: "",
      },
      analysing_data: false,
      sending_information_outside_country: false,
      export_country: "",
    },
    // policy_list: [],
    // policy_details: [],
    // meta : { data_collections : {}, server_collections : {}},
    // focus_title : {}
  },
  cookies_select: [],
  column: COLUMN === null ? [] : COLUMN,
  consent: [],
  select_key: localStorage.getItem("k") ? localStorage.getItem("k") : null,
  loading: false,
  loading_action: false,
};

const Pdpakit = (state = initState, action) => {
  const { domain } = state;
  const { type, payload } = action;
  switch (type) {
    case SET_LIST_DOMAIN: {
      const select_key = payload.body.length > 0 ? payload.body[0].key : null;
      localStorage.setItem("domain", JSON.stringify(payload));
      if (state.select_key === "null" || state.select_key === null) {
        localStorage.setItem("k", select_key);
        return {
          ...state,
          domain: payload,
          banner: payload.body,
          select_key: select_key,
          stampTime: Math.floor(Date.now() / 1000),
        };
      }
      return {
        ...state,
        domain: payload,
        banner: payload.body,
        stampTime: Math.floor(Date.now() / 1000),
      };
    }
    case SET_ADD_DOMAIN: {
      const { body } = domain;
      const { key, limit_scan, name, setting, user_id, created_at, url } =
        payload;
      localStorage.setItem("k", key);
      const DOMAIN_DATA = {
        key,
        limit_scan,
        name,
        setting,
        user_id,
        last_scan_at: created_at,
        url,
        cookies_total: 0,
        cookies_status: "pending",
      };
      body.unshift(DOMAIN_DATA);
      const return_data = {
        ...state,
        domain: {
          ...domain,
          body,
        },
        select_key: key,
        loading: false,
      };
      return {
        ...return_data,
      };
    }
    case SET_UPDATE_DOMAIN: {
      const { body } = domain;
      const {
        key,
        limit_scan,
        name,
        setting,
        user_id,
        created_at,
        url,
        cookies_status,
        cookies_total,
      } = payload;
      const index = body.findIndex((event) => event.key === key);
      const DOMAIN_DATA = {
        key,
        limit_scan,
        name,
        setting,
        user_id,
        last_scan_at: created_at,
        url,
        cookies_total,
        cookies_status,
      };
      let release = body;
      release[index] = DOMAIN_DATA;
      const return_data = {
        ...state,
        domain: {
          ...domain,
          body: release,
        },
      };
      return { ...return_data };
    }
    case SET_DELETE_DOMAIN: {
      const { body } = domain;
      const release_body = body.filter((data) => data.key !== payload);
      if (payload === state.select_key) {
        localStorage.removeItem("k");
      }
      const return_data = {
        ...state,
        domain: {
          ...domain,
          body: release_body,
        },
        select_key: payload === state.select_key ? null : state.select_key,
      };
      return {
        ...return_data,
      };
    }
    case SET_COOKIES: {
      const { cookies } = state;
      const cookies_filter = cookies.filter(
        (item) => item.data.domain_key !== payload.data.domain_key
      );
      return {
        ...state,
        cookies: [...cookies_filter, payload],
      };
    }
    case SET_CONSENT: {
      return {
        ...state,
        consent: [...state.consent, payload],
      };
    }
    case SET_SELECT_KEY: {
      return {
        ...state,
        select_key: payload,
      };
    }
    case SET_BANNER: {
      const { key } = payload;
      const deleteAlready = state.banner.filter((domain) => domain.key !== key);
      return {
        ...state,
        banner: [...deleteAlready, payload],
      };
    }
    case UPDATE_CONFIG_COLUMN: {
      return {
        ...state,
        column: [...action.payload],
      };
    }
    case SET_COOKIES_CATEGORY: {
      const { cookies } = state;
      let SELECT_COOKIES = cookies.filter(
        (item) => item.data.domain_key === payload.domain_key
      );
      let UPSELECT_COOKIES = cookies.filter(
        (item) => item.data.domain_key === payload.domain_key
      );
      SELECT_COOKIES[0].data = payload;
      console.log(SELECT_COOKIES);
      return {
        ...state,
        cookies: [...UPSELECT_COOKIES, ...SELECT_COOKIES],
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case SET_LOADING_ACTION: {
      return {
        ...state,
        loading_action: payload,
      };
    }
    case SET_COOKIES_SELECT: {
      return {
        ...state,
        cookies_select: payload,
      };
    }
    case EDIT_COOKIES_SELECT: {
      const { Name } = payload;
      const { cookies_select } = state;
      let object = cookies_select;
      const position = cookies_select.findIndex(
        (cookies) => cookies.Name === Name
      );
      if (position >= 0) {
        object[position] = payload;
      }
      return {
        ...state,
        cookies_select: object,
      };
    }
    case SET_POLICY_META: {
      return {
        ...state,
        policy: {
          ...state.policy,
          meta: payload,
        },
      };
    }
    case SET_POLICY_DATA: {
      return {
        ...state,
        policy: {
          ...state.policy,
          policy_data: payload,
        },
      };
    }
    case SET_POLICY: {
      return {
        ...state,
        policy: {
          ...state.policy,
          list: true,
          policy_list: payload,
        },
      };
    }
    case SET_POLICY_DETAIL: {
      return {
        ...state,
        policy: {
          ...state.policy,
          policy_data: payload,
        },
        loading: false,
      };
    }
    case SET_DELETE_POLICY: {
      let policy_list = state.policy.policy_list;
      policy_list = policy_list.filter((data) => data.ObjectType !== payload);
      return {
        ...state,
        policy: {
          ...state.policy,
          policy_list,
        },
      };
    }
    case SET_FOCUS_TITLE: {
      return {
        ...state,
        policy: {
          ...state.policy,
          focus_title: payload,
        },
      };
    }
    case SET_POLICY_VERSION: {
      return {
        ...state,
        policy: {
          ...state.policy,
          policy_version: payload,
        },
        loading: false,
        loading_action: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default Pdpakit;
