import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  // AUTH_TOKEN,
  REFRESH_TOKEN,
  // ACCESS_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  GET_USER,
  CHANGE_PROFILE,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signUpSuccess,
  setUser,
  setChangeProfile,
  hideLoading,
} from "../actions/Auth";

import OpenNotification from "views/components/OpenNotification";

import JwtAuthService from "services/JwtAuthService";

export function* signInWithCognitoEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const resp = yield call(JwtAuthService.signIn, email, password);
      if (resp.statusCode === 200) {
        const {
          AuthenticationResult,
          tokenKey,
          userInfo,
          is_package,
          OUToken,
        } = resp;
        // localStorage.setItem("EMAIL", email);
        // localStorage.setItem(AUTH_TOKEN, AuthenticationResult.IdToken);
        // localStorage.setItem(REFRESH_TOKEN, AuthenticationResult.RefreshToken);
        // localStorage.setItem(ACCESS_TOKEN, AuthenticationResult.AccessToken);
        yield put(
          authenticated({
            ...AuthenticationResult,
            ...userInfo,
            email,
            tokenKey,
            is_package,
            OUToken,
          })
        );
      } else {
        yield put(showAuthMessage(resp.Code));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(JwtAuthService.signUp, email, password);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        // localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem("store");
      sessionStorage.clear();
      window.location.reload();
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* getUser() {
  yield takeEvery(GET_USER, function* ({ payload }) {
    try {
      const response = yield call(JwtAuthService.getUser, payload);
      if (response.statusCode === 200) {
        yield put(setUser(response));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* changeProfile() {
  yield takeEvery(CHANGE_PROFILE, function* ({ payload }) {
    try {
      // let data = {
      //   name: payload.name,
      //   email: payload.email,
      //   profile_image: payload.profile_image || "",
      // };
      // console.log({ data });
      const response = yield call(JwtAuthService.changeProfile, payload);
      if (response.statusCode === 200 && response.UserAttributes) {
        yield put(setChangeProfile(response.UserAttributes));
        OpenNotification("success", "updateprofile");
      } else {
        yield put(hideLoading);
      }
    } catch (err) {
      console.log(err);
      yield put(hideLoading);
    }
  });
}

export function* refreshToken() {
  yield takeEvery(REFRESH_TOKEN, function* ({ payload }) {
    try {
      const resp = yield call(JwtAuthService.refreshToken, payload);
      if (resp.statusCode === 200) {
        const { AuthenticationResult } = resp;
        yield put(authenticated(AuthenticationResult));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithCognitoEmail),
    fork(signOut),
    fork(getUser),
    fork(changeProfile),
    fork(signUpWithFBEmail),
    fork(refreshToken),
  ]);
}
