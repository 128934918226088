import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GET_LIST_DOMAIN,
  GET_COOKIES,
  DELETE_DOMAIN,
  ADD_DOMAIN,
  UPDATE_DOMAIN,
  GET_CONSENT,
  UPDATE_COOKIES_CATEGORY,
  GET_POLICY_META,
  SCAN_COOKIES,
  CREATE_POLICY,
  LIST_POLICY,
  DELETE_POLICY,
  GET_POLICY_DETAIL,
  UPDATE_POLICY,
  GET_POLICY_VERSION,
} from "../constants/Pdpakit";

import PdpakitService from "../../services/PdpakitService";
import {
  setListDomain,
  setCookies,
  setAddDomain,
  setDeleteDomain,
  setUpdateDomain,
  setConsent,
  setCookiesCategory,
  setLoading,
  setPolicyMeta,
  getListDomain,
  setPolicy,
  setPolicyDetail,
  setDeletePolicy,
  listPolicy,
  // getPolicyVersion,
  setPolicyVersion,
} from "../actions/Pdpakit";
import OpenNotification from "../../views/components/OpenNotification";
// import OpenLoadingMessage from "../../views/app-views/components/OpenLoadingMessage";

export function* listDomain() {
  yield takeEvery(GET_LIST_DOMAIN, function* () {
    try {
      const resp = yield call(PdpakitService.listDomain);
      if (resp.statusCode === 200) {
        yield put(setListDomain(resp));
      }
      yield put(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* addDomain() {
  yield takeEvery(ADD_DOMAIN, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.addDomain, payload);
      if (resp.statusCode === 200 && resp.error) {
        OpenNotification("error", "domainnotvalid");
        yield put(setLoading(false));
      }
      if (resp.statusCode === 200 && resp.error === undefined) {
        const { body } = resp;
        const { key } = body;
        yield put(setAddDomain(body));
        yield call(PdpakitService.scanCookies, key);
        // yield put(getListDomain)
        OpenNotification("success", "addwebsitesuccess");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteDomain() {
  yield takeEvery(DELETE_DOMAIN, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.deleteDomain, payload);
      if (resp.statusCode === 200) {
        yield put(setDeleteDomain(payload));
        // yield put(getListDomain)
        OpenNotification("success", "deletewebsitesuccess");
      }
      yield put(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateDomain() {
  yield takeEvery(UPDATE_DOMAIN, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.updateDomain, payload);
      if (resp.statusCode === 200) {
        const { body } = resp;
        yield put(setUpdateDomain(body));
        OpenNotification("success", "updatewebsitesuccess");
      }
      // yield put(getListDomain)
      yield put(setLoading(false));
    } catch (err) {
      console.log(err);
      yield put(setLoading(false));
    }
  });
}

export function* getCookies() {
  yield takeEvery(GET_COOKIES, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.getDomainInfo, payload);
      if (resp.statusCode === 200) {
        yield put(setCookies(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* scanCookies() {
  yield takeEvery(SCAN_COOKIES, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.scanCookies, payload);
      if (resp.statusCode === 200) {
        if (resp.body.data) {
          yield put(setCookies(resp.body.data));
        }
        yield put(getListDomain());
        yield put(setLoading(false));
        OpenNotification("success", "requestscansuccess");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getConsent() {
  yield takeEvery(GET_CONSENT, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.getAcceptConsent, payload);
      if (resp.statusCode === 200) {
        const { body } = resp;
        yield put(setConsent({ key: payload, ...body }));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateCookiesCategory() {
  yield takeEvery(UPDATE_COOKIES_CATEGORY, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.updateCookiesCategoryAPI, payload);
      if (resp.statusCode === 200) {
        yield put(setCookiesCategory(resp.body));
        yield put(setLoading(false));
        OpenNotification("success", "updatecookiessuccess");
      } else {
        yield put(setLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getPolicyMeta() {
  yield takeEvery(GET_POLICY_META, function* () {
    try {
      const resp = yield call(PdpakitService.getPolicyMeta);
      if (resp.statusCode === 200) {
        yield put(setPolicyMeta(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* createPolicy() {
  yield takeEvery(CREATE_POLICY, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.createPolicy, payload);
      if (resp.statusCode === 200) {
        yield put(listPolicy());
        if (payload.ObjectType) {
          OpenNotification("success", "updatepolicysuccess");
        }
      }
      console.log(resp);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* listPolicyApi() {
  yield takeEvery(LIST_POLICY, function* () {
    try {
      const resp = yield call(PdpakitService.listPolicy);
      if (resp.statusCode === 200) {
        yield put(setPolicy(resp.body));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getPolicyDetail() {
  yield takeEvery(GET_POLICY_DETAIL, function* ({ payload }) {
    yield put(setLoading(true));
    try {
      const resp = yield call(PdpakitService.getPolicy, payload);
      if (resp.statusCode === 200) {
        yield put(setPolicyDetail(resp.body.policyinfo));
      } else {
        yield put(setLoading(false));
      }
    } catch (err) {
      console.log(err);
      yield put(setLoading(true));
    }
  });
}

export function* updatePolicy() {
  yield takeEvery(UPDATE_POLICY, function* ({ payload }) {
    try {
      yield call(PdpakitService.updatePolicy, payload);
      yield put(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deletePolicy() {
  yield takeEvery(DELETE_POLICY, function* ({ payload }) {
    try {
      const resp = yield call(PdpakitService.deletePolicy, payload);
      if (resp.statusCode === 200) {
        yield put(setDeletePolicy(payload));
        OpenNotification("success", "deletepolicysuccess");
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getPolicyVersionSaga() {
  yield takeEvery(GET_POLICY_VERSION, function* ({ payload }) {
    yield put(setLoading(true));
    try {
      const resp = yield call(PdpakitService.getPolicyVersion, payload);

      if (resp.statusCode === 200) {
        // yield put(getPolicyVersion(payload))
        yield put(setPolicyVersion(resp.body));
      }
    } catch (err) {
      console.log(err);
      yield put(setLoading(true));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listDomain),
    fork(getCookies),
    fork(deleteDomain),
    fork(addDomain),
    fork(updateDomain),
    fork(getConsent),
    fork(updateCookiesCategory),
    fork(getPolicyMeta),
    fork(scanCookies),
    fork(createPolicy),
    fork(listPolicyApi),
    fork(getPolicyDetail),
    fork(deletePolicy),
    fork(updatePolicy),
    fork(getPolicyVersionSaga),
  ]);
}
