import { Link, useLocation } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import IconCustom, { AuditOutlined } from "@ant-design/icons";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    mode,
    domain,
    select_key,
  } = props;

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const location = useLocation();
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  return (
    <>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode={mode}
        style={{ height: "100%", borderRight: 0 }}
        // defaultSelectedKeys={[routeInfo?.key]}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        {navigationConfig.map((menu) => {
          const { body } = domain;

          let path_url = menu.path;
          if (menu.path.split("/").includes("cookies")) {
            path_url = path_url.concat(`/${select_key}`);
          }

          return menu.submenu.length > 0 ? (
            <Menu.ItemGroup
              // icon={
              //   menu.icon ? (
              //     <Icon type={menu?.icon} />
              //   ) : (
              //     <IconCustom
              //       component={menu.iconCustom}
              //       style={{ fontSize: menu.sizeIcon }}
              //     />
              //   )
              // }
              key={menu.key}
              title={setLocale(localization, menu.title)}
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : (
                        !subMenuFirst.iconCustom || (
                          <IconCustom
                            component={subMenuFirst.iconCustom}
                            style={{ fontSize: menu.sizeIcon }}
                          />
                        )
                      )
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : (
                          !subMenuSecond.iconCustom || (
                            <IconCustom
                              component={subMenuSecond.iconCustom}
                              style={{ fontSize: subMenuSecond.sizeIcon }}
                            />
                          )
                        )}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : (
                      !subMenuFirst.iconCustom || (
                        <IconCustom
                          component={subMenuFirst.iconCustom}
                          style={{ fontSize: subMenuFirst.sizeIcon }}
                        />
                      )
                    )}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item
              id={`sidemenu-${menu.key}`}
              disabled={
                menu.key !== "apps-website" &&
                body.length < 1 &&
                menu.path.split("/").includes("cookies")
              }
              key="1"
            >
              {menu.icon ? (
                <AuditOutlined style={{ color: "#fff" }} />
              ) : (
                !menu.iconCustom || (
                  <IconCustom
                    component={menu.iconCustom}
                    style={{ fontSize: menu.sizeIcon }}
                  />
                )
              )}
              <span style={{ color: "#fff" }}>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={path_url} />
              ) : null}
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme, pdpakit }) => {
  const { sideNavTheme, topNavColor } = theme;
  const { domain, select_key } = pdpakit;
  return { sideNavTheme, topNavColor, domain, select_key };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
