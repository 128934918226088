import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
  DashboardOutlined,
  SecurityScanOutlined,
  ProfileOutlined,
  BookOutlined,
  SolutionOutlined,
  ScheduleOutlined,
  FileTextOutlined,
  AuditOutlined
} from "@ant-design/icons";
import { Cookiesicon, Websiteicon, jetIcon } from "assets/svg/iconantdcustom";

let url = window.location.href;
let path = url.split("/");

const pdpakitCookiesNavTree = [
  {
    key: "apps-dashboard",
    path: `${APP_PREFIX_PATH}/pdpakit/cookies/dashboard`,
    title: "dashboard.menu.name",
    icon: DashboardOutlined,
    submenu: [],
  },
  {
    key: "apps-website",
    path: `${APP_PREFIX_PATH}/pdpakit/cookies/website`,
    title: "website.menu.name",
    iconCustom: Websiteicon,
    sizeIcon: "18px",
    submenu: [],
  },
  {
    key: "apps-scan-results",
    path: `${APP_PREFIX_PATH}/pdpakit/cookies/scanresults`,
    title: "scanresult.menu.name",
    icon: SecurityScanOutlined,
    submenu: [],
  },
  {
    key: "apps-cookies",
    path: `${APP_PREFIX_PATH}/pdpakit/cookies/managecookies/show/all`,
    title: "cookies.menu.name",
    iconCustom: Cookiesicon,
    sizeIcon: "18px",
    submenu: [],
  },

  {
    key: "apps-consentbanner",
    path: `${APP_PREFIX_PATH}/pdpakit/cookies/consentbanner`,
    title: "banner.menu.name",
    icon: ProfileOutlined,
    submenu: [],
  },
];

const pdpakitPolicyNavTree = [
  {
    key: "pdpakit-policy",
    path: `${APP_PREFIX_PATH}/pdpakit/policy`,
    title: "policy.menu.name",
    icon: BookOutlined,
    submenu: [],
  },
];
const pdpakitDSARNavTree = [
  {
    key: "pdpakit-datasubject-dashboard",
    path: `${APP_PREFIX_PATH}/pdpakit/datasubject/dashboard`,
    title: "Dashboard",
    icon: DashboardOutlined,
    submenu: [],
  },
  {
    key: "pdpakit-datasubject-requests",
    path: `${APP_PREFIX_PATH}/pdpakit/datasubject/requests`,
    title: "Requests",
    icon: SolutionOutlined,
    submenu: [],
  },
  {
    key: "pdpakit-datasubject-subtask",
    path: `${APP_PREFIX_PATH}/pdpakit/datasubject/subtask`,
    title: "Tasks",
    icon: FileTextOutlined,
    submenu: [],
  },
  {
    key: "pdpakit-datasubject-setting",
    title: "Setting",
    path: "",
    submenu: [
      {
        key: "pdpakit-datasubject-webforms",
        path: `${APP_PREFIX_PATH}/pdpakit/datasubject/webforms`,
        title: "Web Forms",
        icon: ProfileOutlined,
        submenu: [],
      },
      {
        key: "pdpakit-datasubject-workflow",
        path: `${APP_PREFIX_PATH}/pdpakit/datasubject/workflows`,
        title: "Workflows",
        icon: ScheduleOutlined,
        submenu: [],
      },
    ],
  },
];
const pdpakitConsentManagement = [
  {
    key: "pdpakit-consent-management-dashboard",
    path: `${APP_PREFIX_PATH}/pdpakit/consent-management/dashboard`,
    title: "Dashboard",
    icon : DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pdpakit-consent-management-collection-point",
    path: `${APP_PREFIX_PATH}/pdpakit/consent-management/collection-point`,
    title: "Collection Points",
    icon : DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pdpakit-consent-management-preference-centers",
    path: `${APP_PREFIX_PATH}/pdpakit/consent-management/preference-centers`,
    title: "Preference Centers",
    icon : DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "pdpakit-consent-management-report",
  //   path: `${APP_PREFIX_PATH}/pdpakit/management`,
  //   title: "disable",
  //   breadcrumb: false,
  //   icon : "",
  //   submenu: [
  //     {
  //       key: "pdpakit-consent-management-reporting-header",
  //       path: `${APP_PREFIX_PATH}/pdpakit/consent-management/reporting`,
  //       title: "Reporting",
  //       iconCustom: jetIcon,
  //       sizeIcon: "18px",
  //       breadcrumb: false,
  //       submenu: [
  //         {
  //           key: "pdpakit-consent-management-report-data-subject",
  //           path: `${APP_PREFIX_PATH}/pdpakit/consent-management/reporting/data-subjects`,
  //           title: "Data Subjects",
  //           breadcrumb: false,
  //           submenu: [],
  //         },
  //         {
  //           key: "pdpakit-consent-management-report-recipt",
  //           path: `${APP_PREFIX_PATH}/pdpakit/consent-management/reporting/recipts`,
  //           title: "Recipts",
  //           breadcrumb: false,
  //           submenu: [],
  //         },
  //         {
  //           key: "pdpakit-consent-management-report-transactions",
  //           path: `${APP_PREFIX_PATH}/pdpakit/consent-management/reporting/user`,
  //           title: "Transactions",
  //           breadcrumb: false,
  //           submenu: [],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    key: "pdpakit-consent-management-setup",
    path: `${APP_PREFIX_PATH}/pdpakit/management`,
    title: "disable",
    breadcrumb: false,
    icon : "",
    submenu: [
      {
        key: "pdpakit-consent-management-setup-header",
        path: `${APP_PREFIX_PATH}/pdpakit/consent-management/reporting`,
        title: "Setup",
        iconCustom: jetIcon,
        sizeIcon: "18px",
        breadcrumb: false,
        submenu: [
          {
            key: "pdpakit-consent-management-setup-processing-purpose",
            path: `${APP_PREFIX_PATH}/pdpakit/consent-management/setup/processing-purpose`,
            title: "Processing Purpose",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "pdpakit-consent-management-setup-custom-preferences",
            path: `${APP_PREFIX_PATH}/pdpakit/consent-management/setup/custom-preferences`,
            title: "Custom Preferences",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "pdpakit-consent-management-setup-data-element",
            path: `${APP_PREFIX_PATH}/pdpakit/consent-management/setup/data-elements`,
            title: "Data Elements",
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "pdpakit-consent-management-setup-attribute-manager",
          //   path: `${APP_PREFIX_PATH}/pdpakit/consent-management/setup/attribute-manager`,
          //   title: "Attribute Manager",
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
    ],
  },
  // {
  //   key: "pdpakit-consent-management-consent-sitting",
  //   path: `${APP_PREFIX_PATH}/pdpakit/consent-management/consent-sitting`,
  //   title: "Consent Sitting",
  //   icon : DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
];
const pdpakitMangementNavTree = [
  {
    key: "pdpakit-Mangement",
    path: `${APP_PREFIX_PATH}/pdpakit/management`,
    title: "BUSINESS HOME",

    breadcrumb: false,
    submenu: [
      {
        key: "pdpakit-Mangement-submenu",
        path: `${APP_PREFIX_PATH}/pdpakit/management`,
        title: "Organizations",
        iconCustom: jetIcon,
        sizeIcon: "18px",
        breadcrumb: false,
        submenu: [
          {
            key: "pdpakit-Mangement-Users",
            path: `${APP_PREFIX_PATH}/pdpakit/management/user`,
            title: "Users",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "pdpakit-Mangement--UserGroups",
            path: `${APP_PREFIX_PATH}/pdpakit/management/usergroup`,
            title: "User Groups",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "pdpakit-Mangement-Roles",
            path: `${APP_PREFIX_PATH}/pdpakit/management/roles`,
            title: "Roles",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "pdpakit-Mangement-Organizations",
            path: `${APP_PREFIX_PATH}/pdpakit/management/organizations`,
            title: "Organizations",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const pdpakitPortalNavTree = [
  {
    key: "request-overview",
    path: `${APP_PREFIX_PATH}/requestlist/overview`,
    title: "คำร้อง",
    icon: AuditOutlined,
    submenu: [],
  }
]

const MenuRoutePath = () => {
  if (path.includes("cookies")) {
    return [...pdpakitCookiesNavTree];
  } else if (path.includes("policy")) {
    return [...pdpakitPolicyNavTree];
  } else if (path.includes("datasubject")) {
    return [...pdpakitDSARNavTree];
  } else if (path.includes("requestlist")) {
    return [...pdpakitPortalNavTree];
  } else if(path.includes("consent-management")) {
    return [...pdpakitConsentManagement]
  } else if (path.includes("management")) {
    return [...pdpakitMangementNavTree];
  } else {
    return [...pdpakitPortalNavTree];
  }
};

const navigationConfig = MenuRoutePath();

export default navigationConfig;
