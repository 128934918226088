import { connect } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import IntlMessage from "../util-components/IntlMessage";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useEffect } from "react";
import history from "../../history";
import Flex from "components/shared-components/Flex";

export const NavProfile = (props) => {
  const { signOut } = props;
  const { location } = useHistory();

  useEffect(() => {
    let url = location.pathname;
    let path = url.split("/");
    if (!path.includes("profile")) {
      localStorage.setItem("PrvUrl", location.pathname);
    }
  }, [location.pathname]);

  const ButtonSignOut = styled.div`
    cursor: pointer;
    transition: .5s all ease;
    color: #fff;

    &:hover {
      color: #f2f2f2;
    }
  `;
  
  return (
    <ButtonSignOut
      onClick={() => {
        localStorage.removeItem("store");
        sessionStorage.clear();
        window.location.href = "https://www.onefence.co/th/"
      }}

      className="ml-3 mr-3"
    >
      <Flex alignItems="center">
        <LogoutOutlined className="mr-2" style={{ fontSize: 18 }} />
        <span className="font-weight-semibold">
          <IntlMessage id="pdpakit.navprofile.signout" />
        </span>
      </Flex>
    </ButtonSignOut>
  );
};

const mapStateToProps = ({ auth }) => {
  const { Username, UserAttributes } = auth;
  return { Username, UserAttributes };
};

export default connect(mapStateToProps, { signOut })(NavProfile);
