import axios from "axios";

const envUpload = {
  development: "9ae1qml0m4",
  production: "ou71xd0g5b",
};

// FIXME: อันนี้มันเป็นอันเก่า
const envService = {
  development: "3ulz9p9wf9",
  production: "3ulz9p9wf9",
};

const envReport = {
  development: "68aak1kwbc",
  production: "iza1ccpa7a",
};

const envWebformAuth = {
  development: "hq5wvne0w4",
  production: "8bxppt52bj",
};

const envOCR = {
  development: "421s3h3u0m",
  production: "apxjij633d",
}

const envWebform = {
  development: "rwmxx3el2m",
  production: "y0epgctvhe",
}

const getBaseURL = (env) =>
  `https://${
    env[process.env.REACT_APP_SERV || "development"]
  }.execute-api.ap-southeast-1.amazonaws.com/v1`;

const upload = axios.create({
  baseURL: getBaseURL(envUpload),
  timeout: 60000,
});
const service = axios.create({
  baseURL: getBaseURL(envService),
  timeout: 60000,
});
const new_service_get_portal = axios.create({
  baseURL: getBaseURL(envReport),
  timeout: 60000,
});
const service_auth = axios.create({
  baseURL: getBaseURL(envWebformAuth),
  timeout: 60000,
});
const new_service = axios.create({
  baseURL: getBaseURL(envReport),
  timeout: 60000,
});

const ocr_service = axios.create({
  baseURL: getBaseURL(envOCR),
  timeout: 100000,
});
const list_record_service = axios.create({
  baseURL: getBaseURL(envWebform),
  timeout: 100000,
});
const report_service = axios.create({
  baseURL: getBaseURL(envWebform),
  timeout: 100000,
});
const dopa_service = axios.create({
  baseURL: getBaseURL(envOCR),
  timeout: 100000,
});
// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
new_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ocr_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service_auth.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
list_record_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
report_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
upload.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
new_service_get_portal.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);
dopa_service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export {
  service,
  ocr_service,
  new_service,
  service_auth,
  list_record_service,
  report_service,
  upload,
  new_service_get_portal,
  dopa_service,
};
