import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { PANNEL_PREFIX_PATH } from "configs/AppConfig";

const PannelViews = () => {
    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route
                    path={`${PANNEL_PREFIX_PATH}/complete/:id`}
                    component={lazy(() => import(`views/pannel-views/complete-page`))}
                />
            </Switch>
        </Suspense>
    )
}

export default PannelViews