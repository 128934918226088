import {
  LIST_PERSONNEL,
  GET_ALL_USER,
  SET_ALL_USER,
  SET_LIST_USERS,
} from "../constants/Orgs";

const organization = [
  {
    tenant_name: "general",
    description: "test",
    created_at: "2021-11-26T07:59:13",
    organization_name: "Company One",
    default_language: "th",
    created_by: "b5898af5-bb59-43c1-8081-a482257b3d55",
    default_approver: "uuid_user",
    updated_at: "2021-11-26T07:59:13",
    parent_id: "",
    organization_id: "custom_org_id_label",
    updated_by: "b5898af5-bb59-43c1-8081-a482257b3d55",
    UUID: "0aa4f9c7-2133-44b5-a73d-e9b3f3a4e340",
    family_name: "/general/user",
  },
  {
    tenant_name: "general",
    description: "test",
    created_at: "2021-11-26T07:59:13",
    organization_name: "Company Two",
    default_language: "th",
    created_by: "b5898af5-bb59-43c1-8081-a482257b3d55",
    default_approver: "uuid_user",
    updated_at: "2021-11-26T07:59:13",
    parent_id: "",
    organization_id: "custom_org_id_label",
    updated_by: "b5898af5-bb59-43c1-8081-a482257b3d55",
    UUID: "0aa4f9c7-2134-44b5-a73d-e9b3f3a4e340",
    family_name: "/general/user",
  },
];
const personnel = [
  {
    username: "monsak@dga.or.th",
    email: "monsak@dga.or.th",
    tenant_name: "",
    family_name: "",
    uid: "05ed40c4-3ba1-4ae1-b896-9ecb56abfb1f",
    UserStatus: "CONFIRMED",
    ukey: "32dfac83c9261de9d096b6b72998e1e1bab1812afef5af5b1c213f66d802b9dd",
    UserLastModifiedDate: "2021-11-09 06:37:43.501000+00:00",
    UserCreateDate: "2021-11-09 06:37:43.222000+00:00",
  },
  {
    username: "jakkris@computerlogy.com",
    email: "jakkris@computerlogy.com",
    tenant_name: "",
    family_name: "",
    uid: "0c193777-558c-4e28-be44-f3ac053fe99e",
    UserStatus: "CONFIRMED",
    ukey: "87e967b381eac2dcd4ac19769c621b50ff860f2d2bb5229cf86b99518ac941f7",
    UserLastModifiedDate: "2021-11-09 06:37:43.460000+00:00",
    UserCreateDate: "2021-11-09 06:37:43.198000+00:00",
  },
  {
    username: "benzbenz900+general@gmail.com",
    email: "benzbenz900+general@gmail.com",
    tenant_name: "",
    family_name: "/general/user",
    uid: "10fadcbf-6bc7-404a-916c-1109c90b9a85",
    UserStatus: "CONFIRMED",
    ukey: "37126e7b112a7aead0d1f46c44112b3f186a8042bc344f6efb1bfb6f0302e13d",
    UserLastModifiedDate: "2021-11-26 09:58:53.179000+00:00",
    UserCreateDate: "2021-11-26 09:58:34.006000+00:00",
  },
  {
    username: "qwertynaruk@gmail.com",
    email: "qwertynaruk@gmail.com",
    tenant_name: "",
    family_name: "/general/user",
    uid: "11a1e144-2100-4f00-ae8e-ce62e62b7ac6",
    UserStatus: "CONFIRMED",
    ukey: "716bdc12b78188fe0d9d00f52cb7ade8d01134548e04ed885e96793856ef309b",
    UserLastModifiedDate: "2021-11-26 10:37:07.189000+00:00",
    UserCreateDate: "2021-11-26 10:36:35.119000+00:00",
  },
  {
    username: "nichapp059@gmail.com",
    email: "nichapp059@gmail.com",
    tenant_name: "",
    family_name: "",
    uid: "1599cd6f-1541-46b4-b15b-f526476b9ac3",
    UserStatus: "CONFIRMED",
    ukey: "8db9c0509ce55dba50582067fc18b078eaf867cb31215fd13344fa6720d538d9",
    UserLastModifiedDate: "2021-11-09 06:37:43.638000+00:00",
    UserCreateDate: "2021-11-09 06:37:43.244000+00:00",
  },
  {
    username: "pukkanant@arip.co.th",
    email: "pukkanant@arip.co.th",
    tenant_name: "",
    family_name: "",
    uid: "1c62290d-2245-4afb-bd94-e513e6ff5cba",
    UserStatus: "CONFIRMED",
    ukey: "b26733057d01f829fea4a12993149754cdecc09fd9a7ae4ad5d6f975e30dc000",
    UserLastModifiedDate: "2021-11-09 06:37:43.565000+00:00",
    UserCreateDate: "2021-11-09 06:37:43.216000+00:00",
  },
  {
    username: "tanawat_t@hotmail.com",
    email: "tanawat_t@hotmail.com",
    tenant_name: "",
    family_name: "",
    uid: "1d0457d9-cd7e-4619-860c-45fb393a53cf",
    UserStatus: "CONFIRMED",
    ukey: "67fee21d6b0cad9f52bd181f176627381c95204e19401079283c30b9c41d5f52",
    UserLastModifiedDate: "2021-11-09 06:37:43.420000+00:00",
    UserCreateDate: "2021-11-09 06:37:43.143000+00:00",
  },
  {
    username: "c.thanupakon@gmail.com",
    email: "c.thanupakon@gmail.com",
    tenant_name: "",
    family_name: "",
    uid: "2798a9d1-b58d-44d4-9adb-a8df877effac",
    UserStatus: "CONFIRMED",
    ukey: "7a2e8b3b17718a26cc4a08a3550ab6a8c5bf133bbf80293f60cbb6bb3abe29e5",
    UserLastModifiedDate: "2021-11-26 08:37:04.193000+00:00",
    UserCreateDate: "2021-11-09 06:37:43.224000+00:00",
  },
  {
    username: "thanupakon2014@outlook.com",
    email: "thanupakon2014@outlook.com",
    tenant_name: "",
    family_name: "",
    uid: "d3841d17-b7cb-4fbe-a4e8-fab847a64233",
    UserStatus: "CONFIRMED",
    ukey: "7a2e8b3b17718a26cc4a08a3550ab6a8c5bf133bbf80293f60cbb6bb3abe29e4",
    UserLastModifiedDate: "2021-11-26 08:37:04.193000+00:00",
    UserCreateDate: "2021-11-09 06:37:43.224000+00:00",
  },
];

const initState = {
  organization,
  personnel,
  list_users: [],
};

const Orgs = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_PERSONNEL: {
      return {
        ...state,
      };
    }
    case SET_ALL_USER: {
      return {
        ...state,
        list_user: payload,
      };
    }
    case SET_LIST_USERS: {
      return {
        ...state,
        list_users: payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default Orgs;
