import { 
  COMMENT_TRIGGER,
  LIST_REQUEST_PORTAL, 
  SET_LIST_REQUEST_PORTAL, 
  SET_LOADING_LIST
} from "redux/constants/Portal";

export const setListRequestPortal = (data) => {
  return {
    type: SET_LIST_REQUEST_PORTAL,
    payload: data
  };
};

export const listRequestPortal = (data) => {
  return {
    type: LIST_REQUEST_PORTAL,
    payload: data
  };
};

export const setLoadingList = (data) => {
  return {
    type: SET_LOADING_LIST,
    payload: data
  };
};

export const commentTrigger = (data) => {
  return {
    type: COMMENT_TRIGGER,
    payload: data
  };
};