import AppLocale from "lang";

export const useLanguageLabel = () => {
  const { en, th } = AppLocale;
  const locale = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "th";
  const { messages } = locale === "th" ? th : en;
  const setMessageId = (id) => {
    if (id) {
      return messages[id];
    }
    return messages;
  };
  const getLangLocal = locale;
  return { setMessageId, getLangLocal };
};
