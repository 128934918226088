import axios from "axios";
import { AUTH_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import { ENTRY_ROUTE } from "configs/AppConfig";

const service = axios.create({
  baseURL: AUTH_BASE_URL,
  timeout: 60000,
});

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API Response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error) {
      // Remove token and redirect

      if (
        error.response.status === 400 ||
        error.response.status === 403 ||
        error.response.status === 408
      ) {
        localStorage.removeItem(AUTH_TOKEN);
        history.push(ENTRY_ROUTE);
        window.location.reload();
      }
      return Promise.reject(error.response.data);
    }
    // notification.error(notificationParam)
    return Promise.reject(error.response.data);
  }
);

export default service;
