import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import LandingPageLayout from "layouts/landingpage-app-layout";
import AuthLayout from "layouts/auth-layout";
import WebformLayout from "layouts/webform-layout";

import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  PREVIEW_PREFIX_PATH,
  WEBFORM_PREFIX_PATH,
  VERIFY_PREFIX_PATH,
  PANNEL_PREFIX_PATH,
} from "configs/AppConfig";
import PreviewViews from "./preview-views";
import EmailVerification from "./verification-views";
import useBodyClass from "hooks/useBodyClass";

import { getUser } from "redux/actions/Auth";
import PannelViews from "./pannel-views";
import MotionAlert from "components/shared-components/MotionAlert";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
function RouteInterceptorHome({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/app/home",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const {
    locale,
    location,
    tokenKey,
    direction,
    token,
    refresh_token,
    saveTime,
    AccessToken = "",
    expires_at
  } = props;
  const currentAppLocale = AppLocale[locale];

  useBodyClass(`dir-${direction}`);

  const [handleSession, setHandleSession] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const toMiliTime = currentDate.getTime();

    if (toMiliTime > expires_at) {
      setHandleSession(true)
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 3000);
    }
  }, [expires_at]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
       {handleSession &&
        <MotionAlert alert={{ state: handleSession }} customMsg="session การเข้าใช้งานหมดอายุ ระบบกำลังนำท่านออกจากระบบ" />
       }

        <Switch>
          <Route exact path="/">
            <Redirect to="/app" />
          </Route>

          <Route path={PREVIEW_PREFIX_PATH}>
            <PreviewViews direction={direction} location={location} />
          </Route>

          <RouteInterceptor
            path={APP_PREFIX_PATH}
            isAuthenticated={AccessToken}
          >
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
       
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>

          <Route path={WEBFORM_PREFIX_PATH}>
            <WebformLayout direction={direction} />
          </Route>

          <Route path={VERIFY_PREFIX_PATH}>
            <EmailVerification direction={direction} location={location} />
          </Route>

          <Route path={VERIFY_PREFIX_PATH}>
            <EmailVerification direction={direction} location={location} />
          </Route>

          <Route path={"/home"}>
            <EmailVerification direction={direction} location={location} />
          </Route>

          <Route path={"/app"}>
            <LandingPageLayout direction={direction} location={location} />
          </Route>

          <Route path={PANNEL_PREFIX_PATH}>
            <PannelViews direction={direction} />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token, AccessToken, refresh_token, expiredTime, tokenKey, saveTime, expires_at } = auth;
  return {
    locale,
    direction,
    token,
    AccessToken,
    refresh_token,
    expiredTime,
    tokenKey,
    saveTime,
    expires_at
  };
};

const mapDispatchToProps = {
  getUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
