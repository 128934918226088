import { notification } from "antd";
import { useLanguageLabel } from "hooks/useLanguageCustom";
const OpenNotification = (type, desc) => {
  const { setMessageId } = useLanguageLabel();
  const message = setMessageId(`pdpakit.notification.${type}`);
  const description = setMessageId(`pdpakit.notification.${desc}`);

  return notification[type]({
    message,
    description,
    duration: 2,
    placement: "bottomLeft",
  });
};

export default OpenNotification;
