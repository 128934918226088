import {
  service,
  ocr_service,
  new_service,
  service_auth,
  list_record_service,
  report_service,
  new_service_get_portal,
  dopa_service,
} from "axios/FetchPortal";

const PortalService = {
  createFormRequest: async (data, authId) => {
    return await service({
      method: "post",
      url: `/request`,
      data: { data },
      headers: { Authorization: authId },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  requestOTP: async (data) => {
    return await service({
      method: "post",
      url: `/portal/send_email_otp`,
      data,
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  verifyOTP: async (data) => {
    const proms = await service({
      method: "post",
      url: `/portal/verify_email_otp`,
      data,
    });
    if (proms.status !== 200) {
      return Promise.reject(proms);
    }

    return Promise.resolve(proms.status);
  },
  getWebForm: async (webformID) => {
    const inStore = JSON.parse(localStorage.getItem("store"));
    return await new_service_get_portal({
      method: "get",
      url: `/webform?webform_id=6306f00ef03a684b6deb78cb`,
      headers: {
        Authorization: inStore.auth.AccessTokenPolice,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  getRequestPortal: async () => {
    const inStore = JSON.parse(localStorage.getItem("store"));
    return await report_service({
      method: "get",
      url: `/online_report`,
      headers: {
        Authorization: inStore.auth.id_token,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  getRequestPortalById: async (request_id) => {
    const inStore = JSON.parse(localStorage.getItem("store"));

    return await service({
      method: "get",
      url: `/request/${request_id}/client`,
      headers: {
        Authorization:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJlbnpiZW56OTAwQGdtYWlsLmNvbSIsImlhdCI6MTY1MTEzNjA1MSwiZXhwIjoxNjUxMTM3ODUxLCJ0ZW5hbnQiOiJ0aG9uZ2xvciIsIm9yZ2FuaXphdGlvbiI6Ii9wb2xpY2UvdGhvbmdsb3IifQ.1S7MKE1tno7bqBTMXG99eMXpt5GJ63NU6Vy56lQH6IM",
        // Authorization: inStore.auth.AccessToken,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  getRequestReportById: async (request_id) => {
    const inStore = JSON.parse(localStorage.getItem("store"));

    return await report_service({
      method: "get",
      url: `online_report/detail?report_id=${request_id}`,
      headers: {
        Authorization: inStore.auth.id_token,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  getComment: async (request_id) => {
    const inStore = JSON.parse(localStorage.getItem("store"));
    return await service({
      method: "get",
      url: `/request/comment?request_id=${request_id}`,
      headers: {
        Authorization: inStore.auth.AccessToken,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  submitComment: async (data, request_id, ukey) => {
    const inStore = JSON.parse(localStorage.getItem("store"));
    return await service({
      method: "post",
      url: `/request/${request_id}/activities/client?user_key=${ukey}`,
      data: { data },
      headers: {
        Authorization: inStore.auth.AccessToken,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  getDetailThaiIdCardFront: async (data) => {
    try {
      const resp = await ocr_service({
        method: "post",
        url: `/ocr/thaiIdCardFront`,
        data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  getDetailThaiIdCardBack: async (data) => {
    try {
      const resp = await ocr_service({
        method: "post",
        url: `/ocr/thaiIdCardBack`,
        data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  getDetailVerifyFace: async (data) => {
    try {
      const resp = await ocr_service({
        method: "post",
        url: `/facerec/verify`,
        data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  getDetailCompareFace: async (data) => {
    try {
      const resp = await ocr_service({
        method: "post",
        url: `/facerec/compare`,
        data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  getReportTypes: async () => {
    const inStore = JSON.parse(localStorage.getItem("store"));
    return await new_service({
      method: "get",
      url: `/report_types?is_hidden=true`,
      headers: {
        Authorization: inStore.auth.AccessTokenPolice,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  getReportTypesPeople: async () => {
    return await new_service({
      method: "get",
      url: `/report_types/people`,
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  createReportOnline: async (data) => {
    const inStore = JSON.parse(localStorage.getItem("store"));
    return await new_service({
      method: "post",
      url: `/report/online`,
      data,
      headers: {
        Authorization: inStore.auth.id_token,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  updateReportOnline: async (data, report_id) => {
    const inStore = JSON.parse(localStorage.getItem("store"));
    return await new_service({
      method: "put",
      url: `/report/online?report_id=${report_id}`,
      data,
      headers: {
        Authorization: inStore.auth.id_token,
      },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  createReportOnlineNotAuth: async (data) => {
    return await new_service({
      method: "post",
      url: `/report/online_people`,
      data,
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  sendForgetPassword: async (data) => {
    try {
      const resp = await service_auth({
        method: "post",
        url: `/forget_password`,
        data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  verifyForgetPassword: async (data) => {
    try {
      const resp = await service_auth({
        method: "post",
        url: `/verify_forget_password`,
        data,
      });
      console.log(resp);
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  resetPasswordPeople: async (data, Authorization) => {
    try {
      const resp = await service_auth({
        method: "post",
        headers: {
          Authorization,
        },
        url: `/reset_password_people`,
        data: data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  getListByReportRecordId: async (report_record_id) => {
    try {
      const resp = await list_record_service({
        method: "get",
        url: `/track?track_id=${report_record_id}`,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  getInfoPeople: async (Authorization) => {
    try {
      const resp = await list_record_service({
        method: "get",
        url: `/people_info`,
        headers: {
          Authorization,
        },
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  getWebFormReport: async () => {
    return await new_service({
      method: "get",
      url: `/webform/list`,
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  getWebFormReportDetail: async (webform_id) => {
    // const inStore = JSON.parse(localStorage.getItem("store"));
    return await new_service({
      method: "get",
      url: `/webform?webform_id=${webform_id}`,
      // headers: {
      //   Authorization: inStore?.auth?.AccessTokenPolice,
      // },
    })
      .then((resp) => resp)
      .catch((err) => err);
  },
  check_auth_user: async (username) => {
    try {
      const resp = await service_auth({
        method: "get",
        url: `check_auth_user?username=${username}`,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  get_otp: async (data) => {
    try {
      const resp = await service_auth({
        method: "post",
        url: "get_otp",
        data: data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  verify_otp: async (data) => {
    try {
      const resp = await service_auth({
        method: "post",
        url: "verify_otp",
        data: data,
      });
      return resp;
    } catch (error) {
      return error.response.data;
    }
  },
  verify_dopa: async (data) => {
    try {
      const resp = await dopa_service({
        method: "post",
        url: "dopa",
        data: data,
      });
      return resp;
    } catch (error) {
      return error.response;
    }
  },
  getReportPublic: async (report_id) => {
    return new_service({
      method: "get",
      url: `/report_public`,
      params: { report_id },
    }).then((resp) => {
      return resp.data;
    });
  },
};

export default PortalService;
