import styled from "styled-components";
import PropTypes from "prop-types";
export const ImgSizeAuto = styled.img`
  ${({ cursor, widthimg }) => ` 
  
    max-width: ${widthimg}%;
    height: auto;
    ${cursor ? `cursor: pointer;` : ""}
    `}
`;

ImgSizeAuto.PropTypes = {
  cursor: PropTypes.bool,
  widthimg: PropTypes.number,
};
ImgSizeAuto.defaultProps = {
  cursor: false,
  widthimg: 100,
};
