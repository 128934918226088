import { Alert } from "antd";
import IntlMessage from "../../util-components/IntlMessage";
import { motion } from "framer-motion";
import React from "react";

const MotionAlert = ({ alert, type = "error", customMsg = "" }) => {
  return (
    <motion.div
      initial={{ opacity: 0, marginBottom: 0 }}
      animate={{
        opacity: alert.state ? 1 : 0,
        marginBottom: alert.state ? 20 : 0,
      }}
    >
      <Alert
        type={type}
        showIcon
        message={
          customMsg ? 
          customMsg :
          <IntlMessage id={`pdpakit.alert.message.error.${alert.errCode}`} />
        }
      ></Alert>
    </motion.div>
  );
};

export default MotionAlert;
